import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";
import "./styles.css";
import { useDropzone } from "react-dropzone";
import imageCompression from "browser-image-compression";
import { bytesToMegabytes } from "../../../pages/ImagesUpload/utils";
import { getAllowedFileTypes, getFilesErrorMessage, handleFileSelectingError } from "./utils";
import { FileTypeIcon } from "./components/FileTypeIcon";
import { InfoBar } from "../InfoBar";

import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
const IMAGE_EXTENSIONS = ["jpeg", "jpg", "png", "gif", "webp", "heic"];

// Возвращает расширение файла (строчными буквами)
const getFileExtension = (fileName) =>
    fileName?.split(".").pop().toLowerCase() || "";

// Определяет, является ли файл изображением по расширению
const isImageFile = (fileName) => {
    if (!fileName || typeof fileName !== "string") return false;
    const extension = getFileExtension(fileName);
    return IMAGE_EXTENSIONS.includes(extension);
};

// Функция для определения корректного URL для изображения
const getImageSrc = (item) => {
    if (item.preview) return item.preview;
    if (item.url && isImageFile(item.url)) return item.url;
    if (item.path && isImageFile(item.path)) return item.path;
    return null;
};

/**
 * Функция для создания нового объекта File из Blob,
 * гарантируя, что имя, MIME-тип и lastModified установлены корректно.
 */
const createFileFromBlob = (blob, originalName) => {
    const extension = getFileExtension(originalName) || "jpeg";
    // Если оригинальное имя уже содержит расширение, используем его, иначе добавляем
    const fileName = originalName.includes('.') ? originalName : `${originalName}.${extension}`;
    return new File([blob], fileName, {
        type: blob.type || `image/${extension}`,
        lastModified: Date.now(),
    });
};

export const UploadFiles = ({
                                fileTypes,
                                label,
                                setFilesArray,         // Функция для обновления файлов в родительском компоненте
                                maxFiles,
                                maxSize,               // максимальный размер файла в байтах
                                initialFiles = [],     // Существующие файлы (например, с сервера)
                                deletingFilesIds,
                                onFilesChange,
                                setUpdatedFiles,
                                setDeletingFilesIds,
                                isEditing,
                                setIsBusy,             // Новый пропс для управления состоянием "занятости"
                            }) => {
    // Состояния для новых файлов и списка ID удалённых файлов.
    const [newFiles, setNewFiles] = useState([]);
    const [deletedIds, setDeletedIds] = useState([]);
    const [selectedFileError, setSelectedFileError] = useState(null);

    // Используем initialFiles напрямую, отфильтровывая удалённые
    const filteredExistingFiles = useMemo(() => {
        return initialFiles.filter((file) => !deletedIds.includes(file.id));
    }, [initialFiles, deletedIds]);

    // Объединяем отфильтрованные существующие и новые файлы.
    const combinedFiles = useMemo(() => {
        return [...filteredExistingFiles, ...newFiles];
    }, [filteredExistingFiles, newFiles]);

    // Фильтруем дубликаты по id (на всякий случай)
    const uniqueCombinedFiles = useMemo(() => {
        const seen = new Set();
        return combinedFiles.filter((file) => {
            if (!file.id || seen.has(file.id)) return false;
            seen.add(file.id);
            return true;
        });
    }, [combinedFiles]);

    // Текущее количество файлов – используется для проверки возможности добавления новых
    const currentFilesCount = uniqueCombinedFiles.length;
    const maxFilesReached = currentFilesCount >= maxFiles;
    const maxFileSizeDisplay = bytesToMegabytes(Number(maxSize));
    const dropZoneText = maxFilesReached
        ? "Достигнуто максимальное количество файлов, удалите какой-либо файл, чтобы загрузить новый"
        : `Перетащите файлы сюда или кликните. Максимальный размер файла ${maxFileSizeDisplay} МБ`;

    /**
     * Обработка добавления новых файлов:
     * - Для изображений пробуем выполнить сжатие.
     * - Если сжатие прошло успешно, преобразуем полученный Blob в новый File,
     *   чтобы гарантировать наличие корректных метаданных.
     * - Если сжатие не удалось, то выполняется проверка исходного файла на превышение лимита.
     */
    const handleNewFiles = useCallback(async (acceptedFiles) => {
        // Устанавливаем состояние "занятости"
        setIsBusy && setIsBusy(true);
        setSelectedFileError(null);

        const newFilesArray = await Promise.all(
            acceptedFiles.map(async (file) => {
                if (isImageFile(file.name)) {
                    try {
                        const options = {
                            maxSizeMB: 1,           // Максимальный размер после сжатия (в МБ)
                            maxWidthOrHeight: 1920, // Максимальная ширина или высота
                            useWebWorker: true,
                        };
                        const compressedBlob = await imageCompression(file, options);
                        const finalFile = createFileFromBlob(compressedBlob, file.name);
                        return {
                            id: `new-${Date.now()}-${finalFile.name}`,
                            file: finalFile,
                            preview: URL.createObjectURL(finalFile),
                            size: bytesToMegabytes(finalFile.size, 2),
                            name: finalFile.name,
                            type: finalFile.type,
                        };
                    } catch (error) {
                        console.error("Ошибка сжатия, используем оригинальный файл", error);
                        if (file.size > maxSize) {
                            setSelectedFileError("Файл слишком большой");
                            return null;
                        }
                        return {
                            id: `new-${Date.now()}-${file.name}`,
                            file,
                            preview: URL.createObjectURL(file),
                            size: bytesToMegabytes(file.size, 2),
                            name: file.name,
                            type: file.type,
                        };
                    }
                } else {
                    if (file.size > maxSize) {
                        setSelectedFileError("Файл слишком большой");
                        return null;
                    }
                    return {
                        id: `new-${Date.now()}-${file.name}`,
                        file,
                        preview: null,
                        size: bytesToMegabytes(file.size, 2),
                        name: file.name,
                        type: file.type,
                    };
                }
            })
        );

        // Фильтруем возможные null (файлы, не прошедшие проверку)
        const filteredFiles = newFilesArray.filter((f) => f !== null);
        setNewFiles((prev) => [...prev, ...filteredFiles]);
        // Сброс состояния "занятости"
        setIsBusy && setIsBusy(false);
    }, [maxSize, setIsBusy]);

    // Конфигурация dropzone: убираем maxSize, чтобы не отвергать большие файлы сразу
    const { getRootProps, getInputProps } = useDropzone({
        accept: getAllowedFileTypes(fileTypes),
        onDrop: (files) => {
            handleNewFiles(files);
        },
        disabled: maxFilesReached,
        onDropRejected: (error) => setSelectedFileError(handleFileSelectingError(error)),
    });

    // Удаление файла:
    const handleDelete = (file) => {
        if (String(file.id).startsWith("new-")) {
            setNewFiles((prev) => prev.filter((f) => f.id !== file.id));
        } else {
            const deletingFilesArray = deletingFilesIds.includes(file.id)
                ? deletingFilesIds
                : [...deletingFilesIds, file.id];
            setDeletedIds(deletingFilesArray);
        }
    };

    // Обновляем родительские состояния только при изменении уникального списка файлов или deletedIds.
    const prevDataRef = useRef({ files: "", deleted: "" });
    useEffect(() => {
        const currentFilesString = JSON.stringify(uniqueCombinedFiles);
        const currentDeletedString = JSON.stringify(deletedIds);
        if (
            prevDataRef.current.files !== currentFilesString ||
            prevDataRef.current.deleted !== currentDeletedString
        ) {
            if (isEditing) {
                setFilesArray(uniqueCombinedFiles);
                setUpdatedFiles(newFiles.map((item) => item.file));
                onFilesChange && onFilesChange({ files: uniqueCombinedFiles, deletedIds });
            } else {
                setFilesArray(newFiles.map((item) => item.file));
            }
            setDeletingFilesIds(deletedIds);
            prevDataRef.current = {
                files: currentFilesString,
                deleted: currentDeletedString,
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uniqueCombinedFiles, deletedIds, newFiles]);

    return (
        <>
            <div className="upload-files-component_label">{label}</div>
            <div {...getRootProps({ className: "upload-files-component_dropzone" })}>
                <input {...getInputProps()} />
                <div className="upload-files-component_dropzone-text">
                    {dropZoneText}
                </div>
            </div>
            {selectedFileError && (
                <InfoBar text={getFilesErrorMessage(selectedFileError)} />
            )}
            <div className="upload-files-component_files-list">
                {uniqueCombinedFiles.map((item) => {
                    const imageSrc = getImageSrc(item);
                    if (imageSrc) {
                        return (
                            <div key={item.id} className="upload-files-component_image-preview">
                                <img
                                    src={imageSrc}
                                    alt={item.name}
                                    className="upload-files-component_image"
                                    style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "cover",
                                    }}
                                    onError={(e) => {
                                        e.target.style.display = "none";
                                    }}
                                />
                                <button
                                    onClick={() => handleDelete(item)}
                                    className="upload-files-component_delete-image-button"
                                >
                                    <DeleteIcon width="22px" height="22px" stroke="#818c99" />
                                </button>
                            </div>
                        );
                    } else {
                        return (
                            <div key={item.id} className="upload-files-component_file-preview">
                                <FileTypeIcon
                                    width={32}
                                    height={32}
                                    fileExtension={getFileExtension(item.path || item.name)}
                                    fileType={item.type}
                                />
                                <div className="upload-files-component_uploading-file-name">
                                    {item.path || item.name}
                                </div>
                                <button
                                    onClick={() => handleDelete(item)}
                                    className="upload-files-component_delete-file-button"
                                >
                                    <DeleteIcon width="22px" height="22px" stroke="#818c99" />
                                </button>
                            </div>
                        );
                    }
                })}
            </div>
        </>
    );
};

