export const REGEXES = {
  password: /^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]{6,}$/,
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  phone: /^(?:(?:\+7|7|8)[\s-]?(?:\(?\d{3}\)?)[\s-]?\d{3}[\s-]?\d{2}[\s-]?\d{2}|(?:\(?\d{3}\)?)[\s-]?\d{3}[\s-]?\d{2}[\s-]?\d{2})$/
};

export const isTestStand = window.location.host === "stage1.husamhelper.ru";

export const isLocalHost = window.location.host === "localhost:3000";

export const host = isTestStand
  ? "https://stage1.husamhelper.ru"
  : "https://husamhelper.ru";

export const API_ENDPOINTS = {
  flatsTable: "https://mgmdk.ru/bot/api/realty/index.php",
  housings: "/api/v1/old/housings/?getHousings",
  installmentPlanTypes: "/api/v1/old/housings/?getInstallmentPlanTypes",
  housingPricesByFirstPayment: "/api/v1/old/prices/?byFirstPayment",
  housingPricesByPeriod: "/api/v1/old/prices/?byPeriod",
  userSignIn: `${host}/api/v1/auth/login/`,
  userSignUp: `${host}/api/v1/auth/register`,
  getUserInfo: `${host}/api/v1/user/info/`,
  getUserInfoById: `/api/v1/user/private/`,
  getSharedData: "/api/v1/shared-calc/",
  getSharingDataId: "/api/v1/shared-calc/",
  uploadFiles: `${host}/api/v1/file/upload/`,
  verifyEmail: "/api/v1/auth/verify/email/",
  resetPassword: "/api/v1/auth/reset-password/",
  payment: `${host}/api/v1/payment/yoomoney/create`,
  contractAssignments: "/api/v1/assignment",
  uploadNewFiles: "/api/v1/assignment-image/",
  deleteFiles: "/api/v1/assignment-image/bulk/",
  contractAssignmentsByUser: "/api/v1/assignment/by-user/"
};

export const INSTALLMENT_PLANS_LIB = {
  full: {
    name: "full",
    label: "полная стоимость",
  },
  without: {
    name: "without",
    label: "без взноса",
  },
};

export const IMAGE_SIDES = {
  width: "width",
  height: "height",
};

export const APP_MODES = {
  housings: {
    name: "housings",
    id: 1,
    alias: '/',
  },
  contractAssignments: {
    name: "contractAssignments",
    id: 2,
    alias: 'contract-assignment'
  },
};

export const ROUTES = {
  housings: '/',
  contractAssignment: '/contract-assignment',
  payment: '/payment',
};
