import React, { useEffect, useState } from "react";
import "./styles.css";
import { Plans } from "./components/Plans";
import { InstallmentPlan } from "../../components/common/Pricing/InstallmentPlan";
import { RootState } from "../../store/index.ts";
import { HousingsActionCreator } from "../../store/housingsActions.ts";
import { connect } from "react-redux";
import { selectHousingsList } from "../../store/selectors.ts";
import { HousingInfo } from "./components/HousingInfo";
import { useIsMobile } from "../../hooks/useIsMobile";
import { Link, useParams } from "react-router-dom";
import { getHousingById } from "./utils";
import { Error } from "../../components/common/Error";
import { ReactComponent as Chevron } from "../../assets/icons/chevron.svg";
import { Pricing } from "../../components/common/Pricing";

export const _HousingComplex = ({ fetchHousings, housings }) => {
  const [shareData, setShareData] = useState();
  const isMobile = useIsMobile();
  const housingId = useParams()?.id;
  const housing = getHousingById(housings, housingId);

  useEffect(() => {
    if (!housings) {
      fetchHousings();
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [housings]);

  if (!housing.housingName) {
    return (
      <>
        <Link className="housing-complex_back-link" to={"/"}>
          <div className="housing-complex_chevron">
            <Chevron width="16" height="16" fill="#000" />
          </div>
          назад
        </Link>
        <Error
          title="Объект не найден"
          description="Проверьте правильность ссылки"
        />
      </>
    );
  }
  const isChessPlans = housing?.chessPlans?.length > 0;
  const isLayouts = housing?.layouts?.length > 0;

  return (
    <>
      <Link className="housing-complex_back-link" to={"/"}>
        <div className="housing-complex_chevron">
          <Chevron width="16" height="16" fill="#000" />
        </div>
        назад
      </Link>

      <div className="housings_container">
        {!isMobile && (
          <div className="housing-complex_installment-plan-container">
            <Pricing
                housing={housing}
                shareData={shareData}
                setShareData={setShareData}
            />
          </div>
        )}
        <div className="housing-complex_main-container">
          <HousingInfo housing={housing} />
          {(isChessPlans || isLayouts) && (
            <div className="housing-complex_plans-container">
              {isChessPlans && (
                <Plans
                  title="шахматки"
                  chessPlans={housing.chessPlans}
                  defaultName="Шахматка"
                />
              )}
              {isLayouts && (
                <Plans
                  title="планировки"
                  chessPlans={housing.layouts}
                  defaultName="Общий план"
                />
              )}
            </div>
          )}
        </div>
        {isMobile && (
          <div className="housing-complex_installment-plan-container">
            <Pricing
              housing={housing}
              shareData={shareData}
              setShareData={setShareData}
            />
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  housings: selectHousingsList(state),
});

const mapDispatchToProps = {
  fetchFlatsTable: HousingsActionCreator.fetchFlatsTable,
  fetchHousings: HousingsActionCreator.fetchHousings,
};

export const HousingComplex = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_HousingComplex);
