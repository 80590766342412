import { useState } from "react";
import "./styles.css";
import { Button } from "../Button";

const Accordion = ({
  summary,
  details,
  summaryText,
  detailsText,
  moreLink,
  moreButton,
  buttonFullWidth,
  lessButton,
  className = "",
  moreLinkClassName = "",
  moreButtonClassName = "",
  summaryClassName = "",
  detailsClassName = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`accordion-container ${className}`}>
      <div className={summaryClassName}>{summary || <p>{summaryText}</p>}</div>
      <div
        className={`hidden-text ${isOpen ? "show" : ""} ${detailsClassName}`}
      >
        {details || <p>{detailsText}</p>}
      </div>
      <div className="actions">
        {moreLink && (
          <a href={moreLink} className={`more-link ${moreLinkClassName}`}>
            {moreLink}
          </a>
        )}
        {moreButton && (
          <Button
            onClick={() => setIsOpen(!isOpen)}
            className={moreButtonClassName}
            text={isOpen ? lessButton || "Скрыть" : moreButton}
            fullWidth={buttonFullWidth}
          />
        )}
      </div>
    </div>
  );
};

export default Accordion;
