import React, { useState } from "react";
import "./App.css";
import { realtyStore } from "./Realty/store";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { SignUp } from "./Realty/pages/SignUp";
import { CookiesProvider } from "react-cookie";
import { AuthHoc } from "./Realty/components/hocs/AuthHoc";
import { HousingComplexShared } from "./Realty/pages/Shared/components/HousingComplexShared";
import { Header } from "./Realty/components/common/Header";
import { ImagesUpload } from "./Realty/pages/ImagesUpload";
import { Footer } from "./Realty/components/common/Footer";
import { VerifyEmail } from "./Realty/pages/VerifyEmail";
import { Payment } from "./Realty/pages/Payment";
import { Notification } from "./Realty/components/common/Notification";
import { ResetPassword } from "./Realty/pages/ResetPassword";
import { ContractAssignment } from "./Realty/pages/ContractAssignment/components/ContractAssignmentsList";
import { ContractAssignmentEditor } from "./Realty/pages/ContractAssignment/components/ContractAssignmentEditor";
import { ContractAssignmentItem } from "./Realty/pages/ContractAssignment/components/ContractAssignmentItem";
import { isTestStand } from "./Realty/constants/common";
import { Housings } from "./Realty/pages/Housings";
import { HousingComplex } from "./Realty/pages/HousingComplex";
import { ScrollAwareBlock } from "./Realty/components/common/ScrollAwareBlock";
import { SideMenu } from "./Realty/components/common/Header/components/UserBar/components/SideMenu";
import { MainPage } from "./Realty/pages/MainPage";

function App() {
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);
  if (isTestStand) {
    console.log("Current Git Branch:", process.env.REACT_APP_GIT_BRANCH);
  }
  return (
    <>
      <CookiesProvider>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Provider store={realtyStore}>
          <BrowserRouter>
            <AuthHoc>
              <ScrollAwareBlock
                className="header_scroll-container"
                position="top"
                offset={80}
                shadow={true}
              >
                <Header setSideMenuIsOpen={setSideMenuIsOpen} />
              </ScrollAwareBlock>
              <SideMenu isOpen={sideMenuIsOpen} setIsOpen={setSideMenuIsOpen} />
              <Routes>
                <Route path="/" element={<Housings />} />
                <Route
                  path="/housing-complex/:id/"
                  element={<HousingComplex />}
                />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/signin" element={<MainPage />} />
                <Route path="/payment" element={<Payment />} />
                <Route path="/images-upload" element={<ImagesUpload />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route
                  path="/shared/housing/:id/"
                  element={<HousingComplexShared />}
                />
                <Route path="/profile/verify/" element={<VerifyEmail />} />
                <Route
                  path="/contract-assignment"
                  element={<ContractAssignment />}
                  key="list"
                />
                <Route
                  path="/contract-assignment/by-user/:userId"
                  element={<ContractAssignment />}
                  key="list"
                />
                <Route
                  path="/contract-assignment/:id"
                  element={<ContractAssignmentItem />}
                />
                <Route
                  path="/contract-assignment/edit/:id"
                  element={<ContractAssignmentEditor />}
                  key="edit"
                />
                <Route
                  path="/contract-assignment/create"
                  element={<ContractAssignmentEditor />}
                  key="new"
                />
              </Routes>
              <Footer />
              <Notification />
            </AuthHoc>
          </BrowserRouter>
        </Provider>
      </CookiesProvider>
    </>
  );
}

export default App;
