import React from "react";
import "./styles.css";
import { Button } from "../Button";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { ReactComponent as CloseSimpleIcon } from "../../../assets/icons/close-simple.svg";
import { default as ReactModal } from "react-modal";

export const Modal = ({
  isOpen,
  title,
  text,
  actionButtonText,
  actionButtonCallback,
  actionButtonPending,
  cancelButtonText,
  className,
  onClose,
}) => {
  const isMobile = useIsMobile();
  if (isOpen) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "";
  }
  return (
    <ReactModal
      className={"modal_container " + className}
      onRequestClose={onClose}
      isOpen={isOpen}
    >
      <button className="modal_close-icon" onClick={onClose}>
        <CloseSimpleIcon height="70%" width="70%" stroke="#818c99" />
      </button>
      <div className="modal_main">
        <div className="modal_title">{title}</div>
        <div className="modal_text">{text}</div>
        {actionButtonText && (
          <Button
            pending={actionButtonPending}
            text={actionButtonText}
            onClick={actionButtonCallback}
            fullWidth={isMobile}
          />
        )}
        {cancelButtonText && (
          <Button text={cancelButtonText} onClick={onClose} />
        )}
      </div>
    </ReactModal>
  );
};
