import React, {useEffect, useState} from "react";
import "./styles.css";
import { ReactComponent as Logo } from "../../../assets/logo_horizontal_color.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { RootState } from "Realty/store";
import { selectUserToken } from "../../../store/selectors";
import { isShared } from "../../../utils";
import { UserBar } from "./components/UserBar";
import { APP_MODES } from "../../../constants/common";
import { Tabs } from "../Tabs";
import { normalizeAlias } from "./utils";
import { TABS } from "./constants";

export const _Header = ({
                          setSideMenuIsOpen
                        }) => {const location = useLocation();
  const alias = normalizeAlias(useLocation().pathname);
  const currentModeKey = Object.keys(APP_MODES).find(
    (key) => APP_MODES[key].alias === alias,
  );
  const navigate = useNavigate();

  const [mode, setMode] = useState(APP_MODES[currentModeKey]?.id || undefined);
  const handleChooseMode = (mode: number) => {
    const targetMode = Object.values(APP_MODES).find(
      (item) => item.id === mode,
    );
    if (targetMode) {
      setMode(targetMode.id);
      navigate(targetMode.alias);
    }
  };

  useEffect(() => {
    setMode(APP_MODES[currentModeKey]?.id || undefined)
  }, [alias]);

  if (isShared) {
    return (
      <div className="header_container">
        <div className="header_logo">
          <Logo width="100%" height="100%" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="header_container">
        <Link to="/">
          <div className="header_logo">
            <Logo width="100%" height="100%" />
          </div>
        </Link>
        <UserBar setSideMenuIsOpen={setSideMenuIsOpen} />
      </div>
      {/*{mode && (*/}
      {/*    <Tabs*/}
      {/*        tabs={TABS}*/}
      {/*        setActiveTab={(e) => handleChooseMode(e)}*/}
      {/*        activeTab={mode}*/}
      {/*    />*/}
      {/*)}*/}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  token: selectUserToken(state),
});

const mapDispatchToProps = {};

export const Header = connect(mapStateToProps, mapDispatchToProps)(_Header);
