import React, { useState, useRef, useEffect } from "react";
import "./styles.css";
import { DefaultAvatar } from "../DefaultAvatar";
import { ReactComponent as TelegramIcon } from "../../../assets/icons/social/telegram.svg";
import { ReactComponent as WhatsappIcon } from "../../../assets/icons/social/whatsapp.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/icons/social/instagram.svg";
import { ReactComponent as Chevron } from "../../../assets/icons/chevron.svg";
import { formatInternationalPhone } from "../../../utils";

export const UserCard = ({ user }) => {
  const [showSocials, setShowSocials] = useState(false);
  const socialsRef = useRef(null);
  const shouldRenderSocials =
    user && (user.whatsapp || user.telegram || user.instagram);
  const phone = formatInternationalPhone(user?.phone);

  const toggleSocials = () => {
    setShowSocials((prev) => !prev);
  };

  // Обновляем высоту контейнера при изменении состояния
  useEffect(() => {
    if (socialsRef.current) {
      if (showSocials) {
        socialsRef.current.style.height = `${socialsRef.current.scrollHeight}px`;
      } else {
        socialsRef.current.style.height = "0px";
      }
    }
  }, [showSocials]);

  // Формируем ссылки для соцсетей
  const whatsappLink = user.whatsapp && `https://wa.me/${user.whatsapp}`;
  const telegramLink = user.telegram && `https://t.me/${user.telegram}`;
  const instagramLink =
    user.instagram && `https://instagram.com/${user.instagram}`;

  return (
    <div className="usercard_container">
      <div className="usercard_row">
        <div className="usercard_avatar">
          <DefaultAvatar name={user.name} color={user.avatarColor} />
        </div>
        <div className="usercard_main-info">
          <div className="usercard_name">{user.name}</div>
          <div className="usercard_phone">
            <a href={`tel:${phone}`} className="usercard_phone-link">
              {phone}
            </a>
          </div>
        </div>
        {shouldRenderSocials && (
          <div className="usercard_toggle" onClick={toggleSocials}>
            <Chevron className={`usercard_chevron ${showSocials ? "open" : ""}`} />
          </div>
        )}
      </div>

      {/* Контейнер для соцсетей с динамической высотой */}
      <div ref={socialsRef} className="usercard_socials">
        <div className="usercard_row">
          <div className="usercard_secondary-info">
            <ul className="usercard_contacts">
              {user.whatsapp && (
                <li className="usercard_contact">
                  <a
                    href={whatsappLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="usercard_contact-link"
                  >
                    <WhatsappIcon width="44px" height="44px" /> {user.whatsapp}
                  </a>
                </li>
              )}
              {user.telegram && (
                <li className="usercard_contact">
                  <a
                    href={telegramLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="usercard_contact-link"
                  >
                    <TelegramIcon width="44px" height="44px" /> {user.telegram}
                  </a>
                </li>
              )}
              {user.instagram && (
                <li className="usercard_contact">
                  <a
                    href={instagramLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="usercard_contact-link"
                  >
                    <InstagramIcon width="44px" height="44px" />{" "}
                    {user.instagram}
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
