import React from "react";
import "./styles.css";
import {
  calculateClientsMarkup,
  calculateRequiredPayment,
  calculateSellFullPrice,
  getFilesPaths,
} from "../../utils";
import { ContractAssignmentPropsType } from "../../types";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import { RootState } from "../../../../store";
import { ContractAssignmentActionCreator } from "../../../../store/ContractAssignment/actions";
import { connect } from "react-redux";

export const _ContractAssignmentListItem = ({
  contractAssignment,
  isMine,
  ref,
  setEditingContract,
  setDeletingContractId,
}: ContractAssignmentPropsType) => {
  const navigate = useNavigate();
  const handleNavigate = (id: number) => {
    navigate(`/contract-assignment/${id}/`);
  };
  const {
    id,
    area,
    fullPaymentSellAreaWorth,
    fullPaymentBoughtAreaWorth,
    masterAgentMarkup,
    boughtFirstPayment,
    paidSum,
    images,
    name,
    address,
    rooms,
    floor,
  } = contractAssignment;

  const formattedRooms = Number(rooms) === 0 ? 'ст' : `${rooms}-к`;

  const fullPrice = calculateSellFullPrice(
    area,
    fullPaymentSellAreaWorth,
    masterAgentMarkup,
  );

  const clientsMarkup = calculateClientsMarkup(
    area,
    fullPaymentSellAreaWorth,
    fullPaymentBoughtAreaWorth,
  );

  const requiredPayment = calculateRequiredPayment(
    boughtFirstPayment,
    paidSum,
    masterAgentMarkup,
    clientsMarkup,
  );
  const imagesPaths = getFilesPaths(images);

  const handleEdit = (e) => {
    e.stopPropagation();
    navigate(`/contract-assignment/edit/${id}`);
  };

  const handleSetDeletingId = (e) => {
    e.stopPropagation();
    setDeletingContractId(id);
  };

  return (
    <li className="contract-assignment-list-item_container" ref={ref}>
      <button
        className="contract-assignment-list-item_button"
        onClick={() => handleNavigate(id)}
      >
        <div
          className="contract-assignment-list-item_image"
          style={{
            backgroundImage: `url(${imagesPaths[0] || 'https://husamhelper.ru/storage/images/phpi34fvnh4atr96ZehtUx.png'})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <div className="contract-assignment-list-item_info">
          <div
            className="contract-assignment-list-item_info-row"
            style={{ flexGrow: 1 }}
          >
            <div className="contract-assignment-list-item_main-block">
              <div className="contract-assignment-list-item_title">{name}</div>
              <div className="contract-assignment-list-item_description">
                {address}
              </div>
            </div>
            <div className="contract-assignment-list-item_price-block">
              <div className="contract-assignment-list-item_full-price">
                {fullPrice.toLocaleString()} ₽
              </div>
              <div className="contract-assignment-list-item_required-payment">
                {requiredPayment.toLocaleString()} ₽
              </div>
            </div>
          </div>
          <div className="contract-assignment-list-item_info-row">
            <div className="contract-assignment-list-item_details">
              <div>{area} м²</div>
              {formattedRooms && <div>{formattedRooms}</div>}
              <div>{floor} этаж</div>
              {isMine && (
                <div className="contract-assignment-list-item_control">
                  <button
                    className="contract-assignment-list-item_control-icon"
                    onClick={handleEdit}
                  >
                    <EditIcon width="20px" height="20px" stroke="#818c99" />
                  </button>
                  <button
                    className="contract-assignment-list-item_control-icon"
                    onClick={handleSetDeletingId}
                  >
                    <DeleteIcon width="22px" height="22px" stroke="#818c99" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </button>
    </li>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {
  setEditingContract: ContractAssignmentActionCreator.setEditingContract,
  setDeletingContractId: ContractAssignmentActionCreator.setDeletingContractId,
};

export const ContractAssignmentListItem = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_ContractAssignmentListItem);
