import React from "react";
import "./styles.css";
import { About } from "./components/About";
import { SignIn } from "../SignIn";

export const MainPage = () => {
  return (
    <div className="main-page_container">
      <div className="main-page_title">
        HusamHelper — технологии, которые работают на риэлтора
      </div>
      {/*<p>*/}
      {/*    Инновационные инструменты для риэлторов и агентств: от актуальной базы объектов до удобных калькуляторов*/}
      {/*    и системы управления объявлениями.*/}
      {/*</p>*/}
      <div className="main-page_content">
        <About />
        <SignIn />
      </div>
    </div>
  );
};
