import React, { useState, useEffect } from "react";
import "./styles.css"; // Подключаем стили

/**
 * ScrollAwareBlock
 * @param {string} position - положение блока: "top" или "bottom"
 * @param {React.ReactNode} children - содержимое блока
 * @param {string} [className] - дополнительные классы
 * @param {number|string} [offset] - отступ (например, в пикселях или другой единице),
 *                                  который будет добавлен под блок при position="top"
 * @param {boolean} [shadow] - если true, то добавляется тень, когда пользователь не в начале страницы
 */
export const ScrollAwareBlock = ({
                                     position = "bottom",
                                     children,
                                     className = "",
                                     offset,
                                     shadow = false
                                 }) => {
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollTop, setLastScrollTop] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if (scrollTop > lastScrollTop) {
                // Скролл вниз – скрываем блок
                setIsVisible(false);
            } else {
                // Скролл вверх – показываем блок
                setIsVisible(true);
            }

            setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [lastScrollTop]);

    // Определяем классы в зависимости от положения и видимости
    const blockClass = `scroll-aware-block ${className} ${position} ${isVisible ? "" : "hide"}`;

    // Если включена опция shadow и страница прокручена (не в начале), добавляем стиль тени
    const shadowStyle = shadow && lastScrollTop > 0 ? { boxShadow: "0px 2px 24px 0px #00000014" } : {};

    return (
        <>
            <div className={blockClass} style={shadowStyle}>
                {children}
            </div>
            {/* Если блок позиционирован сверху и задан отступ, рендерим placeholder */}
            {position === "top" && offset && (
                <div style={{ height: typeof offset === "number" ? `${offset}px` : offset }} />
            )}
        </>
    );
};
