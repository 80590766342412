import React from "react";
import './styles.css';

export const Switcher = ({checked, onChange, className, label}) => {
    return (
        <div className={"switcher_container " + (className ? className : "")} onClick={() => onChange(!checked)}>
            <div className={"switcher_toggle" + (checked ? " switcher_toggle-checked" : "")}>
                <div className="switcher_circle" />
            </div>
            <div className="switcher_label">
                {label}
            </div>
        </div>
    );
};
