export const FILE_TYPES = {
  pdf: {
    mime: "application/pdf",
    extensions: [".pdf", ".PDF"],
  },
  xls: {
    mime: "application/vnd.ms-excel",
    extensions: [".xls", ".XLS"],
  },
  xlsx: {
    mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    extensions: [".xlsx", ".XLSX"],
  },
  png: {
    mime: "image/png",
    extensions: [".png", ".PNG"],
  },
  jpg: {
    mime: "image/jpeg",
    extensions: [".jpg", ".jpeg", ".JPG", ".JPEG"],
  },
  gif: {
    mime: "image/gif",
    extensions: [".gif", ".GIF"],
  },
};

export const ERROR_TRANSLATIONS = {
  "file-too-large": "файл превышает допустимый размер",
  "file-too-small": "файл меньше минимального размера",
  "file-invalid-type": "неверный тип файла",
  "too-many-files": "превышено допустимое количество файлов"
};
