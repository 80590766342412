import React from "react";
import "./styles.css";
import { ReactComponent as HomeIcon } from "../../../../../../../assets/icons/navigation/home.svg";
import { ReactComponent as ListIcon } from "../../../../../../../assets/icons/navigation/list.svg";
import { ReactComponent as PaymentIcon } from "../../../../../../../assets/icons/navigation/credit-card.svg";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "../../../../../../../constants/common";
import { normalizeAlias } from "../../../../utils";

export const NavBar = ({ onNavigate }) => {
  const location = normalizeAlias(useLocation()?.pathname);
  const activeRoute = Object.values(ROUTES).find(
    (route) =>
      location?.startsWith(normalizeAlias(route)) ||
      location === normalizeAlias(route),
  );
  const strokeColor = "#818c99";
  const strokeActiveColor = "#fff";
  return (
    <div className="side-menu_item">
      <div className="side-menu_item-title">Навигация</div>
      <Link
        className={
          "navbar_link-item" +
          (activeRoute === ROUTES.housings ? " navbar_active-route" : "")
        }
        to="/"
        onClick={onNavigate}
      >
        <HomeIcon
          width="24px"
          height="24px"
          stroke={
            activeRoute === ROUTES.housings ? strokeActiveColor : strokeColor
          }
        />
        Недвижимость от застройщика
      </Link>
      <Link
        className={
          "navbar_link-item" +
          (activeRoute === ROUTES.contractAssignment
            ? " navbar_active-route"
            : "")
        }
        to="/contract-assignment"
        onClick={onNavigate}
      >
        <ListIcon
          width="24px"
          height="24px"
          stroke={
            activeRoute === ROUTES.contractAssignment
              ? strokeActiveColor
              : strokeColor
          }
        />
        Переуступка
      </Link>
      <Link
        className={
          "navbar_link-item" +
          (activeRoute === ROUTES.payment ? " navbar_active-route" : "")
        }
        to="/payment"
        onClick={onNavigate}
      >
        <PaymentIcon
          width="24px"
          height="24px"
          stroke={
            activeRoute === ROUTES.payment ? strokeActiveColor : strokeColor
          }
        />
        Тарифы
      </Link>
    </div>
  );
};
