import {ERROR_TRANSLATIONS, FILE_TYPES} from "./constants";

export const getAllowedFileTypes = (fileTypes) => {
  let allowedFileTypes = {};
  if (fileTypes && fileTypes.length > 0) {
    fileTypes.map((item) => {
      if (Object.keys(FILE_TYPES).includes(item)) {
        allowedFileTypes[FILE_TYPES[item].mime] = FILE_TYPES[item].extensions;
      }
    });
  }
  return allowedFileTypes;
};

export const getFormattedFileTypeName = (filetype) => {
  let formattedFileTypeName = '';
  if (filetype) {
    Object.keys(FILE_TYPES).map(key => {
      if (FILE_TYPES[key].mime === filetype) {
        formattedFileTypeName = key;
      }
    })
  }
  return formattedFileTypeName;
}

export const handleFileSelectingError = (errorsArray) => {
  const formattedErrorsArray = errorsArray?.map(item => {
    return ({
      fileName: item.file.name,
      errors: item.errors,
    });
  });
  return formattedErrorsArray;
}

export const getFilesErrorMessage = (errorFiles) => {
  if (!errorFiles || errorFiles.length === 0) {
    return '';
  }

  // Получаем список имён файлов
  const fileNames = errorFiles.map(fileObj => fileObj.fileName);

  // Собираем уникальные сообщения об ошибках, используя словарь переводов.
  const errorMessagesSet = new Set();
  errorFiles.forEach(fileObj => {
    fileObj.errors.forEach(err => {
      // Используем перевод из словаря или, если его нет, оригинальное сообщение.
      const translatedMessage = ERROR_TRANSLATIONS[err.code] || err.message;
      errorMessagesSet.add(translatedMessage);
    });
  });
  const errorMessages = Array.from(errorMessagesSet);

  // Определяем, сколько файлов (для корректного склонения)
  const isMultiple = fileNames.length > 1;
  const filesText = fileNames.join(', ');
  const fileWord = isMultiple ? 'файлы' : 'файл';
  const verb = isMultiple ? 'не загружены' : 'не загружен';
  const errorsText = errorMessages.join(', ');

  return `Упс! К сожалению, ${fileWord} ${filesText} ${verb}, так как ${errorMessages.join(', ')}.`;
}
