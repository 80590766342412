import React, { useState } from "react";
import "./styles.css";
import { Tabs } from "../../Tabs";
import { TableComponent } from "./TableComponent";
import { INSTALLMENT_PLAN_TYPES } from "../constants";
import { getLastUpdateDate } from "./utils";
import { Error } from "../../../../components/common/Error";

export const PriceList = ({ pricesByFirstPayment, pricesByPeriod }) => {
  const [activeTab, setActiveTab] = useState(
    pricesByFirstPayment?.floorRanges?.length > 0
      ? INSTALLMENT_PLAN_TYPES.byFirstPayment.id
      : INSTALLMENT_PLAN_TYPES.byPeriod.id,
  );

  const updatedAt = getLastUpdateDate(
    activeTab,
    pricesByFirstPayment,
    pricesByPeriod,
  );

  const tabs = {};
  if (pricesByFirstPayment?.floorRanges?.length > 0) {
    tabs.byFirstPayment = INSTALLMENT_PLAN_TYPES.byFirstPayment;
  }
  if (pricesByPeriod?.floorRanges?.length > 0) {
    tabs.byPeriod = INSTALLMENT_PLAN_TYPES.byPeriod;
  }

  return (
    <div className="price-table-component">
      <div className="pricelist_title">Прайс</div>
      {updatedAt && (
        <div className="pricelist_subtitle">
          последнее обновление {updatedAt}
        </div>
      )}
      {Object.keys(tabs).length > 1 && (
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      )}
      {activeTab === INSTALLMENT_PLAN_TYPES.byFirstPayment.id &&
        pricesByFirstPayment && (
          <TableComponent data={pricesByFirstPayment} type="firstPayment" />
        )}
      {activeTab === INSTALLMENT_PLAN_TYPES.byPeriod.id && pricesByPeriod && (
        <TableComponent data={pricesByPeriod} />
      )}
    </div>
  );
};
