import React, { useEffect, useState } from "react";
import "./styles.css";
import {getBasePrice, getFirstPayment, getInstallmentPlans, getLowestOption} from "./utils.ts";
import { SelectInstallmentOption } from "../components/SelectInstallmentOption";
import { Input } from "../../../Input";
import { RootState } from "../../../../../store";
import { HousingsActionCreator } from "../../../../../store/housingsActions.ts";
import { connect } from "react-redux";
import {
  selectPricesByFirstPaymentData,
  selectPricesByFirstPaymentError,
  selectPricesByFirstPaymentPending,
  selectRequiredPayment,
} from "../../../../../store/selectors.ts";
import {
  getAreas,
  getFlatViewTypes,
  getFloorRanges,
  getInstallmentPeriods,
} from "../utils.ts";
import { ByFirstPaymentSkeleton } from "./components/ByFirstPaymentSkeleton";
import { InfoRow } from "../../../InfoRow";
import { Error } from "../../../Error";
import { MANUAL_AREA } from "../../constants";

export const _ByFirstPayment = ({
  pricesByFirstPayment,
  requiredPayment,
  setShareData,
}) => {
  const [area, setArea] = useState(0);
  const [installmentPeriod, setInstallmentPeriod] = useState(0);
  const [areaWorth, setAreaWorth] = useState(0);
  const [firstPayment, setFirstPayment] = useState(0);
  const [fullPrice, setFullPrice] = useState(0);
  const [rest, setRest] = useState(0);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [markup, setMarkup] = useState(0);
  const [installmentOption, setInstallmentOption] = useState();
  const [flatViewType, setFlatViewType] = useState();
  const [floorRange, setFloorRange] = useState();
  const [fullPaymentPrice, setFullPaymentPrice] = useState(0);

  const floorRanges = getFloorRanges(pricesByFirstPayment);
  const flatViewTypes = getFlatViewTypes(floorRange);
  const installmentPeriods = getInstallmentPeriods(floorRange);
  const installmentPlans = getInstallmentPlans(
    floorRange,
    installmentPeriod?.value,
  );
  const areas = getAreas(pricesByFirstPayment, flatViewType);

  useEffect(() => {
    if (areaWorth > 0 && !!area) {
      setFullPrice(area?.value * areaWorth);
    }
    if (!!installmentPeriod && areaWorth > 0) {
      const countFullPrice = rest ? rest : fullPrice;
      setMonthlyPayment(Math.round(countFullPrice / installmentPeriod.value));
    }
    setMarkup(fullPrice - fullPaymentPrice);
  });

  useEffect(() => {
    if (firstPayment === 0) {
      setRest(0);
    } else {
      setRest(fullPrice - firstPayment);
    }
  }, [firstPayment, areaWorth, fullPrice]);

  useEffect(() => {
    if (installmentOption) {
      setFirstPayment(
        getFirstPayment(
          installmentOption,
          requiredPayment,
          area?.value,
          flatViewType,
        ),
      );
      setAreaWorth(installmentOption.value?.prices[flatViewType?.value?.id]);
    }
  }, [installmentOption, flatViewType, installmentPeriod]);

  useEffect(() => {
    setInstallmentPeriod(installmentPeriods.fullPrice);
    setInstallmentOption({
      name: "полная стоимость",
      value: {
        id: "full",
        prices: getBasePrice(floorRange)
      }
    });
    setFullPaymentPrice(0);
    setFullPrice(0);
    setArea(null);
  }, [floorRange, flatViewType]);

  useEffect(() => {
    if (installmentPeriod.value !== '0') {
      const lowestPlan = getLowestOption(installmentPlans);
      setInstallmentOption(lowestPlan);
    } else {
      setInstallmentOption({
        name: "полная стоимость",
        value: {
          id: "full",
          prices: getBasePrice(floorRange)
        }
      });
    }
  }, [installmentPeriod]);

  useEffect(() => {
    if (!!floorRange && !!area) {
      const basePrice = flatViewType
        ? installmentPlans?.full?.value?.prices[flatViewType.value.id]
        : 0;
      setFullPaymentPrice(basePrice * area.value);
    }
  }, [floorRange, area, flatViewType]);

  const shareData = {
    floor: floorRange?.value?.floorRange,
    flatType: flatViewType?.value?.name,
    area: area?.value,
    installmentPeriod: installmentPeriod?.value,
    installmentOption: installmentOption?.value?.id,
    areaWorth: areaWorth,
    firstPayment: firstPayment,
    fullPrice,
    rest,
    monthlyPayment,
    markup,
  };

  useEffect(() => {
    setShareData(shareData);
  }, [shareData]);

  return (
    <>
      <SelectInstallmentOption
        options={floorRanges}
        label="выберите этажи"
        selectedOption={floorRange}
        setSelectedOption={setFloorRange}
      />
      {floorRange && (
        <>
          <SelectInstallmentOption
            options={flatViewTypes}
            label="выберите тип квартиры"
            selectedOption={flatViewType}
            setSelectedOption={setFlatViewType}
          />
          <SelectInstallmentOption
            options={areas}
            label="выберите квадратуру"
            selectedOption={area}
            setSelectedOption={setArea}
          />
          {area && area.name === MANUAL_AREA && (
            <Input
              value={area.value}
              onChange={(value) =>
                setArea({ name: MANUAL_AREA, value })
              }
              label="введите квадратуру"
            />
          )}
          <SelectInstallmentOption
            options={installmentPeriods}
            label="выберите срок рассрочки"
            selectedOption={installmentPeriod}
            setSelectedOption={setInstallmentPeriod}
          />
          {!!installmentPeriod && installmentPeriod.value !== '0' && (
            <SelectInstallmentOption
              options={installmentPlans}
              selectedOption={installmentOption}
              setSelectedOption={setInstallmentOption}
              label="процент первого взноса:"
            />
          )}
        </>
      )}
      <div className="first-payment_container">
        <Input
          name="areaWorth"
          type="number"
          placeholder="0 ₽"
          value={areaWorth > 0 && areaWorth}
          onChange={setAreaWorth}
          label="стоимость квадрата:"
        />
        {!!installmentPeriod && installmentPeriod.value !== '0' && (
          <Input
            name="firstPayment"
            type="number"
            placeholder="0 ₽"
            value={firstPayment > 0 && firstPayment}
            onChange={setFirstPayment}
            label="первоначальный взнос:"
          />
        )}
        <InfoRow title="полная стоимость" value={fullPrice} unit="₽" />
        {!!installmentPeriod && installmentPeriod.value !== '0' && (
          <>
            <InfoRow title="остаток" value={rest} unit="₽" />
            <InfoRow
              title="ежемесячный платеж"
              value={monthlyPayment}
              unit="₽"
            />
            {!!markup && <InfoRow title="наценка" value={markup} unit="₽" />}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  pricesByFirstPayment: selectPricesByFirstPaymentData(state),
  requiredPayment: selectRequiredPayment(state),
});

export const ByFirstPayment = connect(
  mapStateToProps,
  null,
)(_ByFirstPayment);
