import React, {useEffect, useRef, useState} from "react";
import "./styles.css";
import { RootState } from "../../../../store";
import { connect } from "react-redux";
import { ContractAssignmentActionCreator } from "../../../../store/ContractAssignment/actions";
import {
  selectContractAssignmentsListCurrentPage,
  selectContractAssignmentsListData,
  selectContractAssignmentsListError,
  selectContractAssignmentsListLastPage,
  selectContractAssignmentsListPending,
  selectDeletingContractAssignmentError,
  selectDeletingContractAssignmentPending,
  selectDeletingContractAssignmentSuccess,
  selectDeletingContractId,
} from "../../../../store/ContractAssignment/selectors";
import { ContractAssignmentListItem } from "../ContractAssignmentListItem";
import { selectUserInfo, selectUserPending } from "../../../../store/selectors";
import { ContractAssignmentListSkeleton } from "./components/ContractAssignmentListSkeleton";
import { Error } from "../../../../components/common/Error";
import { sortByDate } from "../../utils";
import { ReactComponent as SortIcon } from "../../../../assets/icons/sort.svg";
import { ScrollAwareBlock } from "../../../../components/common/ScrollAwareBlock";
import { Button } from "../../../../components/common/Button";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { Modal } from "../../../../components/common/Modal";
import { UserActionCreator } from "../../../../store/userActions";
import { Tabs } from "../../../../components/common/Tabs";
import { RENDER_MODES } from "../../constants";

export const _ContractAssignment = ({
  userPending,
  pending,
  deletingPending,
  deletingError,
  deletingSuccess,
  error,
  user,
  contractAssignmentsList,
  lastPage,
  currentPage,
  deletingContractId,
                                      clearContractAssignments,
  getContractAssignments,
  getContractAssignmentsByUser,
  setDeletingContractId,
  deleteContractAssignmentItem,
  setNotification,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const byUser = location?.pathname.includes('by-user');
  const userId = useParams()?.userId;
  const SORTING_TYPE = {
    newest: "newest",
    oldest: "oldest",
  };
  const [isFetching, setIsFetching] = useState(false);
  const [sortBy, setSortBy] = useState(SORTING_TYPE.newest);
  const [selectedUserId, setSelectedUserId] = useState(userId);
  const [renderByUserId, setRenderByUserId] = useState(byUser ? 2 : 1);
  const nextPage = currentPage + 1;

  useEffect(() => {
    if (!selectedUserId && user) {
      setSelectedUserId(user.id);
    }
  }, [user]);

  const isManualLoadRef = useRef(false);

  useEffect(() => {
    if (user && contractAssignmentsList && contractAssignmentsList.length < 1 && !pending && !isFetching && !isManualLoadRef.current) {
      clearContractAssignments();
      loadMoreAssignments(1);
    }
    isManualLoadRef.current = false; // Сбрасываем флаг после возможного запроса
  }, [user, renderByUserId]);

  useEffect(() => {
    if (user && !pending && !isFetching) {
      clearContractAssignments();
      isManualLoadRef.current = true; // Флаг, чтобы следующая `useEffect` знала о загрузке
      loadMoreAssignments(1);
    }
  }, [renderByUserId]);


  useEffect(() => {
    if (!!deletingContractId && deletingSuccess) {
      setDeletingContractId(undefined);
      setNotification({
        isOpen: true,
        value: {
          title: "Объявление удалено",
          description: `Вы успешно удалили объявление`,
          closeIcon: true,
        },
      });
    }
  }, [deletingSuccess]);

  const loadMoreAssignments = async (page) => {
    if (isFetching || pending) return;

    setIsFetching(true);
    if (renderByUserId === 2) {
      await getContractAssignmentsByUser({ userId: selectedUserId, page });
    } else {
      await getContractAssignments({ page });
    }
    // Передаём номер страницы
    setIsFetching(false);
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100 &&
      !isFetching &&
      !pending
    ) {
      if (currentPage < lastPage) {
        loadMoreAssignments(nextPage);
        return nextPage;
      }
    }
  };

  const handleAdd = () => {
    navigate("/contract-assignment/create");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isFetching, pending]);

  if (userPending || (pending && contractAssignmentsList?.length === 0)) {
    return <ContractAssignmentListSkeleton />;
  }

  if (!contractAssignmentsList || error) {
    return (
        <>
          {!byUser && (
              <Tabs
                  tabs={RENDER_MODES}
                  activeTab={renderByUserId}
                  setActiveTab={setRenderByUserId}
              />
          )}
          <Error
              title="Что-то пошло не так"
              description="Не удалось загрузить объявления. Попробуйте ещё раз позже."
              buttonText="повторить"
              retryCallback={() => loadMoreAssignments(1)}
          />
        </>
    );
  }

  //contractAssignmentsList = sortByDate(contractAssignmentsList, sortBy);
  return (
    <>
      {!byUser && (
          <Tabs
              tabs={RENDER_MODES}
              activeTab={renderByUserId}
              setActiveTab={setRenderByUserId}
          />
      )}
      <div className="contract-assignment_container">
        {/*<button*/}
        {/*  onClick={() =>*/}
        {/*    setSortBy(*/}
        {/*      sortBy === SORTING_TYPE.newest*/}
        {/*        ? SORTING_TYPE.oldest*/}
        {/*        : SORTING_TYPE.newest,*/}
        {/*    )*/}
        {/*  }*/}
        {/*  className="contract-assignment_sort-button"*/}
        {/*>*/}
        {/*  <SortIcon width="100%" height="100%" stroke="#818c99" />*/}
        {/*</button>*/}
        <ul>
          {contractAssignmentsList.map((item) => {
            const isMine = Number(user?.id) === Number(item?.userId);
            return (
              <ContractAssignmentListItem
                key={item.id}
                isMine={isMine}
                contractAssignment={item}
              />
            );
          })}
        </ul>
        {isFetching && <ContractAssignmentListSkeleton />}
      </div>
      {!byUser && (
          <ScrollAwareBlock
              className="contract-assignment_add-button"
              position="bottom"
          >
            <Button onClick={handleAdd} text="добавить" fullWidth />
          </ScrollAwareBlock>
      )}
      <Modal
        onClose={() => setDeletingContractId(undefined)}
        isOpen={!!deletingContractId}
        title="Удалить объявление?"
        text="Вы уверены, что хотите удалить объявление?"
        actionButtonText="Удалить"
        actionButtonCallback={() =>
          deleteContractAssignmentItem({ id: deletingContractId })
        }
        actionButtonPending={deletingPending}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  userPending: selectUserPending(state),
  pending: selectContractAssignmentsListPending(state),
  deletingPending: selectDeletingContractAssignmentPending(state),
  deletingError: selectDeletingContractAssignmentError(state),
  deletingSuccess: selectDeletingContractAssignmentSuccess(state),
  error: selectContractAssignmentsListError(state),
  contractAssignmentsList: selectContractAssignmentsListData(state),
  lastPage: selectContractAssignmentsListLastPage(state),
  currentPage: selectContractAssignmentsListCurrentPage(state),
  user: selectUserInfo(state),
  deletingContractId: selectDeletingContractId(state),
});

const mapDispatchToProps = {
  clearContractAssignments: ContractAssignmentActionCreator.clearContractAssignments,
  getContractAssignments:
    ContractAssignmentActionCreator.getContractAssignments,
  getContractAssignmentsByUser:
    ContractAssignmentActionCreator.getContractAssignmentsByUser,
  setDeletingContractId: ContractAssignmentActionCreator.setDeletingContractId,
  deleteContractAssignmentItem:
    ContractAssignmentActionCreator.deleteContractAssignmentItem,
  setNotification: UserActionCreator.setNotification,
};

export const ContractAssignment = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_ContractAssignment);
