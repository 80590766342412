import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Navigation, Pagination, Zoom, Scrollbar } from "swiper/modules";
import "swiper/css/bundle";
import "./styles.css";
import { getImageBiggerSide } from "../../../../utils";
import { IMAGE_SIDES } from "../../../../constants/common";

export const Slider = ({ images }) => {
    const [slide, setSlide] = useState(0);
    const [biggerSide, setBiggerSide] = useState("width");
    const containerRef = useRef(null);

    // Если массив изображений пустой – используется запасной URL
    const imagesArray =
        images && images.length > 0
            ? images
            : ["https://husamhelper.ru/storage/images/phpi34fvnh4atr96ZehtUx.png"];
    const imageUrl = imagesArray[slide];

    const handleChangeSlide = (index) => {
        setSlide(index);
        const newImageUrl = imagesArray[index];
        if (containerRef.current) {
            containerRef.current.style.background = `url("${newImageUrl}") no-repeat center/cover`;
        }
    };

    useEffect(() => {
        getImageBiggerSide(imageUrl).then((side) => {
            setBiggerSide(side);
        });
    }, [imageUrl]);

    return (
        <div
            className="slider_container"
            style={{
                backgroundImage: `url(${imageUrl})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
            ref={containerRef}
        >
            <div className="slider_blur">
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Zoom]}
                    spaceBetween={50}
                    slidesPerView={1}
                    pagination={{
                        clickable: true,
                        el: ".slider_pagination",
                    }}
                    scrollbar={{ draggable: true }}
                    style={{
                        "--swiper-pagination-color": "#FFC800",
                        "--swiper-navigation-color": "#FFC800",
                    }}
                    zoom={{ maxRatio: 3, minRatio: 1 }}
                    onSlideChange={(swiper) => handleChangeSlide(swiper.activeIndex)}
                >
                    {imagesArray.map((image, index) => (
                        <SwiperSlide key={index}>
                            <div className="swiper-zoom-container">
                                <img
                                    src={image}
                                    alt={`image ${index}`}
                                    width={biggerSide === IMAGE_SIDES.width ? "100%" : "auto"}
                                    height={biggerSide === IMAGE_SIDES.height ? "100%" : "auto"}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className="slider_pagination" />
        </div>
    );
};
