import React, { useEffect, useState } from "react";
import "./styles.css";
import { RootState } from "Realty/store";
import {
  selectInstallmentPlanTypesError,
  selectInstallmentPlanTypesList,
  selectInstallmentPlanTypesPending,
  selectPricesByFirstPaymentData,
  selectPricesByFirstPaymentError,
  selectPricesByFirstPaymentPending,
  selectPricesByPeriodData,
  selectPricesByPeriodError,
  selectPricesByPeriodPending,
} from "../../../store/selectors";
import { HousingsActionCreator } from "../../../store/housingsActions";
import { connect } from "react-redux";
import Accordion from "../Accordion";
import { InstallmentPlan } from "./InstallmentPlan";
import { InstallmentPlanSkeleton } from "./InstallmentPlan/InstallmentPlanSkeleton";
import { Error } from "../Error";
import { PriceList } from "./PriceList";
import { INSTALLMENT_PLAN_TYPES } from "./constants";

export const _Pricing = ({
  housing,
  installmentPlans,
  pending,
  error,
  pricesByPeriod,
  pendingByPeriod,
  errorByPeriod,
  pricesByFirstPayment,
  pendingByFirstPayment,
  errorByFirstPayment,
  fetchInstallmentPlanTypes,
  fetchPricesByPeriod,
  fetchPricesByFirstPayment,
  shareData,
  setShareData,
}) => {
  const [option, setOption] = useState();
  const pendingPrices = pending || pendingByPeriod || pendingByFirstPayment;
  const errorPrices = error || errorByPeriod || errorByFirstPayment;

  const hasMultiTypes =
    installmentPlans && Object.keys(installmentPlans)?.length > 1;

  useEffect(() => {
    if (!!installmentPlans && hasMultiTypes) {
      setOption(INSTALLMENT_PLAN_TYPES.byFirstPayment.id);
    }
    if (!!installmentPlans && !hasMultiTypes) {
      setOption(INSTALLMENT_PLAN_TYPES[Object.keys(installmentPlans)]?.id);
    }
  }, [installmentPlans]);

  useEffect(() => {
    if (installmentPlans && !pendingByFirstPayment) {
      fetchPricesByFirstPayment(housing.housingName);
    }
    if (installmentPlans && !pendingByPeriod) {
      fetchPricesByPeriod(housing.housingName);
    }
  }, [installmentPlans]);

  useEffect(() => {
    fetchInstallmentPlanTypes(housing.housingName);
  }, []);

  if (pendingPrices) {
    return <InstallmentPlanSkeleton />;
  }

  if (errorPrices) {
    return (
      <Error
        title="Данные о ценах не загрузились"
        retryCallback={() => fetchInstallmentPlanTypes(housing.housingName)}
      />
    );
  }

  if (pricesByPeriod?.floorRanges?.length === 0 && pricesByFirstPayment?.floorRanges?.length === 0) {
    return (
        <Error
            title="Данные о ценах отсутствуют"
            description="Возможно, скоро они появятся."
        />
    );
  }

  return (
    <div className="pricing_container">
      {installmentPlans && (
        <>
          <PriceList
            pricesByFirstPayment={pricesByFirstPayment}
            pricesByPeriod={pricesByPeriod}
          />
          <Accordion
            details={
              <InstallmentPlan
                option={option}
                setOption={setOption}
                installmentPlans={installmentPlans}
                housing={housing}
                shareData={shareData}
                setShareData={setShareData}
                hasMultiTypes={hasMultiTypes}
              />
            }
            moreButton="рассчитать рассрочку"
            lessButton="скрыть калькулятор"
            buttonFullWidth
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  installmentPlans: selectInstallmentPlanTypesList(state),
  pending: selectInstallmentPlanTypesPending(state),
  error: selectInstallmentPlanTypesError(state),
  pricesByPeriod: selectPricesByPeriodData(state),
  pendingByPeriod: selectPricesByPeriodPending(state),
  errorByPeriod: selectPricesByPeriodError(state),
  pricesByFirstPayment: selectPricesByFirstPaymentData(state),
  pendingByFirstPayment: selectPricesByFirstPaymentPending(state),
  errorByFirstPayment: selectPricesByFirstPaymentError(state),
});

const mapDispatchToProps = {
  fetchInstallmentPlanTypes: HousingsActionCreator.fetchInstallmentPlanTypes,
  fetchPricesByFirstPayment: HousingsActionCreator.fetchPricesByFirstPayment,
  fetchPricesByPeriod: HousingsActionCreator.fetchPricesByPeriod,
};

export const Pricing = connect(mapStateToProps, mapDispatchToProps)(_Pricing);
