import React from "react";
import { INSTALLMENT_PLANS_LIB } from "../../../../../constants/common";

export const TableComponent = ({ data }) => {
    if (!data) {
        return null;
    }

    // Сортируем этажи по убыванию (по первому числу в строке floorRange)
    const sortedFloors = [...data.floorRanges].sort((a, b) => {
        const aFloor = parseInt(a.floorRange.split('-')[0], 10);
        const bFloor = parseInt(b.floorRange.split('-')[0], 10);
        return bFloor - aFloor;
    });

    // Определяем, с какими данными работаем:
    // Если у первого элемента prices есть поле installmentPlan – это byFirstPayment
    const isByFirstPayment =
        data.floorRanges &&
        data.floorRanges.length > 0 &&
        data.floorRanges[0].prices &&
        data.floorRanges[0].prices[0].hasOwnProperty("installmentPlan");

    if (isByFirstPayment) {
        // Собираем уникальные сроки (installmentPeriod)
        const periodsSet = new Set();
        sortedFloors.forEach((floor) => {
            floor.prices.forEach((price) => {
                if (price.installmentPeriod) {
                    periodsSet.add(price.installmentPeriod);
                }
            });
        });
        const periods = Array.from(periodsSet).sort(
            (a, b) => parseInt(a, 10) - parseInt(b, 10)
        );

        // Собираем уникальные варианты первого взноса (installmentPlan)
        const plansSet = new Set();
        sortedFloors.forEach((floor) => {
            floor.prices.forEach((price) => {
                plansSet.add(price.installmentPlan);
            });
        });
        const installmentPlans = Array.from(plansSet);

        return (
            <>
                {periods.map((period, idx) => (
                    <div key={idx} className="period-table">
                        {/* Заголовок периода вынесен за пределы скроллируемой области */}
                        <h3 className="period-header">Срок: {period} мес.</h3>
                        {/* Отдельный контейнер для скроллинга таблицы */}
                        <div className="table-container">
                            <table className="price-table">
                                <thead>
                                <tr>
                                    <th className="sticky-col" rowSpan="2">
                                        Этажи
                                    </th>
                                    {installmentPlans.map((plan, i) => (
                                        <th key={i} colSpan="2">
                                            {INSTALLMENT_PLANS_LIB[plan]?.label || plan}
                                        </th>
                                    ))}
                                </tr>
                                <tr>
                                    {installmentPlans.map((plan, i) => (
                                        <React.Fragment key={i}>
                                            <th>Обычная</th>
                                            <th>Видовая</th>
                                        </React.Fragment>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {sortedFloors.map((floor, index) => {
                                    // Для данного срока выбираем цены для каждого варианта взноса
                                    const priceMap = {};
                                    floor.prices
                                        .filter((price) => price.installmentPeriod === period)
                                        .forEach((price) => {
                                            priceMap[price.installmentPlan] = price;
                                        });
                                    return (
                                        <tr key={index}>
                                            <td className="sticky-col">{floor.floorRange}</td>
                                            {installmentPlans.map((plan, i) => {
                                                const price = priceMap[plan];
                                                return (
                                                    <React.Fragment key={i}>
                                                        <td>{price ? price.commonFlat : "-"}</td>
                                                        <td>{price ? (price.fancyViewFlat || "-") : "-"}</td>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ))}
            </>
        );
    } else {
        // Вариант byPeriod: убираем столбцы с процентами и периодами,
        // оставляем только этажи и цены (обычная и видовая)
        return (
            <div className="table-container">
                <table className="price-table">
                    <thead>
                    <tr>
                        <th className="sticky-col">Этажи</th>
                        <th>Обычная</th>
                        <th>Видовая</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sortedFloors.map((floor, index) => {
                        // Выбираем один объект цены для данного этажа.
                        // Например, если имеется вариант с installmentPercent === "full", то берём его,
                        // иначе – первый из массива.
                        let price;
                        if (floor.prices && floor.prices.length > 0) {
                            price =
                                floor.prices.find((p) => p.installmentPercent === "full") ||
                                floor.prices[0];
                        }
                        return (
                            <tr key={index}>
                                <td className="sticky-col">{floor.floorRange}</td>
                                <td>{price ? price.commonFlat : "-"}</td>
                                <td>{price ? (price.fancyViewFlat || "-") : "-"}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );
    }
};
