import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../../utils";
import { API_ENDPOINTS } from "../../constants/common.ts";

const ContractAssignmentActionTypes = {
  SET_OBJECT_INFO: "objectInfo/set",
  SET_AREA: "area/set",
  SET_NAME: "name/set",
  SET_ROOMS: "rooms/set",
  SET_ADDRESS: "address/set",
  SET_IMAGES: "images/set",
  SET_UPDATED_IMAGES: "updatedImages/set",
  SET_FLAT_NUMBER: "flatNumber/set",
  SET_LAYOUT: "layout/set",
  SET_UPDATED_LAYOUT: "updatedLayout/set",
  SET_FLOOR: "floor/set",
  SET_MONTHLY_PAYMENT: "monthlyPayment/set",
  SET_REST_PAYMENT: "restPayment/set",
  SET_REST_MONTHS: "restMonths/set",
  SET_PAID_SUM: "paidSum/set",
  SET_PAID_MONTHS: "paidMonths/set",
  SET_FULL_PAYMENT_BOUGHT_AREA_WORTH: "fullPaymentBoughtAreaWorth/set",
  SET_FULL_PAYMENT_SELL_AREA_WORTH: "fullPaymentSellAreaWorth/set",
  SET_BOUGHT_FIRST_PAYMENT: "boughtFirstPayment/set",
  SET_MASTER_AGENT_MARKUP: "masterAgentMarkup/set",
  SET_RECALCULATION_PERCENT: "recalculationPercent/set",
  SET_RECALCULATION_PERIOD: "recalculationPeriod/set",
  SET_PRIVATE: "private/set",
  SET_EDITING_CONTRACT: "editingContract/set",
  CLEAR_EDITING_CONTRACT: "editingContract/clear",
  CLEAR_CONTRACT_ASSIGNMENTS: "contractAssignment/clear",
  SET_DELETING_FILES_IDS: "deletingFilesIds/set",
  SET_DELETING_CONTRACT_ID: "deletingContractAssignmentId/set",
  GET_CONTRACT_ASSIGNMENTS: "contractAssignments/get",
  GET_CONTRACT_ASSIGNMENTS_BY_USER: "contractAssignmentsByUser/get",
  CREATE_CONTRACT_ASSIGNMENT: "contractAssignment/create",
  UPDATE_CONTRACT_ASSIGNMENT: "contractAssignment/update",
  UPLOAD_NEW_FILES: "contractAssignmentNewFiles/upload",
  DELETE_FILES: "contractAssignmentFiles/delete",
  DELETE_CONTRACT_ASSIGNMENT: "contractAssignment/delete",
  GET_CONTRACT_ASSIGNMENT_ITEM: "contractAssignmentItem/get",
};

export const ContractAssignmentActionCreator = {
  setObjectInfo: createAction(ContractAssignmentActionTypes.SET_OBJECT_INFO),
  setArea: createAction(ContractAssignmentActionTypes.SET_AREA),
  setName: createAction(ContractAssignmentActionTypes.SET_NAME),
  setRooms: createAction(ContractAssignmentActionTypes.SET_ROOMS),
  setAddress: createAction(ContractAssignmentActionTypes.SET_ADDRESS),
  setFlatNumber: createAction(ContractAssignmentActionTypes.SET_FLAT_NUMBER),
  setLayout: createAction(ContractAssignmentActionTypes.SET_LAYOUT),
  setUpdatedLayout: createAction(
    ContractAssignmentActionTypes.SET_UPDATED_LAYOUT,
  ),
  setFloor: createAction(ContractAssignmentActionTypes.SET_FLOOR),
  setMonthlyPayment: createAction(
    ContractAssignmentActionTypes.SET_MONTHLY_PAYMENT,
  ),
  setRestPayment: createAction(ContractAssignmentActionTypes.SET_REST_PAYMENT),
  setRestMonths: createAction(ContractAssignmentActionTypes.SET_REST_MONTHS),
  setPaidSum: createAction(ContractAssignmentActionTypes.SET_PAID_SUM),
  setPaidMonths: createAction(ContractAssignmentActionTypes.SET_PAID_MONTHS),
  setFullPaymentBoughtAreaWorth: createAction(
    ContractAssignmentActionTypes.SET_FULL_PAYMENT_BOUGHT_AREA_WORTH,
  ),
  setFullPaymentSellAreaWorth: createAction(
    ContractAssignmentActionTypes.SET_FULL_PAYMENT_SELL_AREA_WORTH,
  ),
  setBoughtFirstPayment: createAction(
    ContractAssignmentActionTypes.SET_BOUGHT_FIRST_PAYMENT,
  ),
  setMasterAgentMarkup: createAction(
    ContractAssignmentActionTypes.SET_MASTER_AGENT_MARKUP,
  ),
  setRecalculationPercent: createAction(
    ContractAssignmentActionTypes.SET_RECALCULATION_PERCENT,
  ),
  setRecalculationPeriod: createAction(
    ContractAssignmentActionTypes.SET_RECALCULATION_PERIOD,
  ),
  setImages: createAction(ContractAssignmentActionTypes.SET_IMAGES),
  setUpdatedImages: createAction(
    ContractAssignmentActionTypes.SET_UPDATED_IMAGES,
  ),
  setPrivate: createAction(ContractAssignmentActionTypes.SET_PRIVATE),
  setEditingContract: createAction(
    ContractAssignmentActionTypes.SET_EDITING_CONTRACT,
  ),
  clearEditingContract: createAction(
    ContractAssignmentActionTypes.CLEAR_EDITING_CONTRACT,
  ),
  clearContractAssignments: createAction(
    ContractAssignmentActionTypes.CLEAR_CONTRACT_ASSIGNMENTS,
  ),
  setDeletingFilesIds: createAction(
    ContractAssignmentActionTypes.SET_DELETING_FILES_IDS,
  ),
  setDeletingContractId: createAction(
    ContractAssignmentActionTypes.SET_DELETING_CONTRACT_ID,
  ),
  getContractAssignments: createAsyncThunk(
    ContractAssignmentActionTypes.GET_CONTRACT_ASSIGNMENTS,
    async ({ page }) => {
      try {
        const result = await apiClient.get(
          `${API_ENDPOINTS.contractAssignments}?page=${page}`,
        );

        return result;
      } catch (error) {
        return error.response;
      }
    },
  ),
  getContractAssignmentsByUser: createAsyncThunk(
    ContractAssignmentActionTypes.GET_CONTRACT_ASSIGNMENTS_BY_USER,
    async ({ userId, page }) => {
      try {
        const result = await apiClient.get(
          `${API_ENDPOINTS.contractAssignmentsByUser}${userId}?page=${page}`,
        );

        return result;
      } catch (error) {
        return error.response;
      }
    },
  ),
  createContractAssignment: createAsyncThunk(
    ContractAssignmentActionTypes.CREATE_CONTRACT_ASSIGNMENT,
    async ({
      objectInfo,
      images,
      area,
      name,
      rooms,
      address,
      flatNumber,
      layout,
      floor,
      monthlyPayment,
      restPayment,
      restMonths,
      paidSum,
      paidMonths,
      fullPaymentBoughtAreaWorth,
      fullPaymentSellAreaWorth,
      boughtFirstPayment,
      masterAgentMarkup,
      recalculationPercent,
      recalculationPeriod,
      isPrivate,
    }) => {
      try {
        const result = await apiClient.post(
          API_ENDPOINTS.contractAssignments,
          {
            objectInfo,
            images,
            area,
            name,
            rooms,
            address,
            flatNumber,
            layout,
            floor,
            monthlyPayment,
            restPayment,
            restMonths,
            paidSum,
            paidMonths,
            fullPaymentBoughtAreaWorth,
            fullPaymentSellAreaWorth,
            boughtFirstPayment,
            masterAgentMarkup,
            recalculationPercent,
            recalculationPeriod,
            isPrivate,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        );

        return result;
      } catch (error) {
        return error.response;
      }
    },
  ),
  updateContractAssignment: createAsyncThunk(
    ContractAssignmentActionTypes.UPDATE_CONTRACT_ASSIGNMENT,
    async ({
      id,
      objectInfo,
      area,
      name,
      rooms,
      address,
      flatNumber,
      floor,
      monthlyPayment,
      restPayment,
      restMonths,
      paidSum,
      paidMonths,
      fullPaymentBoughtAreaWorth,
      fullPaymentSellAreaWorth,
      boughtFirstPayment,
      masterAgentMarkup,
      recalculationPercent,
      recalculationPeriod,
      isPrivate,
    }) => {
      const result = await apiClient.post(
        `${API_ENDPOINTS.contractAssignments}/${id}?_method=PUT`,
        {
          objectInfo,
          area,
          name,
          rooms,
          address,
          flatNumber,
          floor,
          monthlyPayment,
          restPayment,
          restMonths,
          paidSum,
          paidMonths,
          fullPaymentBoughtAreaWorth,
          fullPaymentSellAreaWorth,
          boughtFirstPayment,
          masterAgentMarkup,
          recalculationPercent,
          recalculationPeriod,
          isPrivate,
        },
      );

      return result;
    },
  ),
  uploadNewFiles: createAsyncThunk(
    ContractAssignmentActionTypes.UPLOAD_NEW_FILES,
    async ({ id, type, files }) => {
      const result = await apiClient.post(
        `${API_ENDPOINTS.uploadNewFiles}${id}?_method=PUT`,
        {
          type,
          files,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      return {
        fileType: type,
        response: result,
      };
    },
  ),
  deleteFiles: createAsyncThunk(
    ContractAssignmentActionTypes.DELETE_FILES,
    async ({ assignmentId, ids }) => {
      try {
        const result = await apiClient.delete(
          `${API_ENDPOINTS.deleteFiles}${assignmentId}`,
          {
            data: {
              ids,
            },
          },
        );

        return result;
      } catch (error) {
        return error.response;
      }
    },
  ),
  getContractAssignmentItem: createAsyncThunk(
    ContractAssignmentActionTypes.GET_CONTRACT_ASSIGNMENT_ITEM,
    async ({ id }) => {
      try {
        const result = await apiClient.get(
          `${API_ENDPOINTS.contractAssignments}/${id}`,
        );

        return result;
      } catch (error) {
        return error.response;
      }
    },
  ),
  deleteContractAssignmentItem: createAsyncThunk(
    ContractAssignmentActionTypes.DELETE_CONTRACT_ASSIGNMENT,
    async ({ id }) => {
      const result = await apiClient.delete(
        `${API_ENDPOINTS.contractAssignments}/${id}`,
      );

      return result;
    },
  ),
};
