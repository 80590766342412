import React, {useEffect} from "react";

export const useResetEditor = ({
                                   contractAssignmentsData,
    reset,
                               }) => {
    useEffect(() => {
        if (contractAssignmentsData) {
            reset({
                name: contractAssignmentsData.name || "",
                address: contractAssignmentsData.address || "",
                objectInfo: contractAssignmentsData.objectInfo || "",
                area:
                    contractAssignmentsData.area === 0
                        ? ""
                        : contractAssignmentsData.area,
                rooms: contractAssignmentsData.rooms,
                flatNumber: contractAssignmentsData.flatNumber || "",
                floor:
                    contractAssignmentsData.floor === 0
                        ? ""
                        : contractAssignmentsData.floor,
                monthlyPayment:
                    contractAssignmentsData.monthlyPayment === 0
                        ? ""
                        : contractAssignmentsData.monthlyPayment,
                paidSum:
                    contractAssignmentsData.paidSum === 0
                        ? ""
                        : contractAssignmentsData.paidSum,
                fullPaymentBoughtAreaWorth:
                    contractAssignmentsData.fullPaymentBoughtAreaWorth === 0
                        ? ""
                        : contractAssignmentsData.fullPaymentBoughtAreaWorth,
                fullPaymentSellAreaWorth:
                    contractAssignmentsData.fullPaymentSellAreaWorth === 0
                        ? ""
                        : contractAssignmentsData.fullPaymentSellAreaWorth,
                boughtFirstPayment:
                    contractAssignmentsData.boughtFirstPayment === 0
                        ? ""
                        : contractAssignmentsData.boughtFirstPayment,
                masterAgentMarkup:
                    contractAssignmentsData.masterAgentMarkup === 0
                        ? ""
                        : contractAssignmentsData.masterAgentMarkup,
                isPrivate: contractAssignmentsData.isPrivate || false,
            });
        }
    }, [contractAssignmentsData, reset]);
}
