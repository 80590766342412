import { RootState } from "Realty/store";
import {createSelector} from "@reduxjs/toolkit";

export const selectContractAssignment = (state: RootState) =>
  state.contractAssignmentReducer;

export const selectCreateContractAssignment = (state: RootState) =>
  selectContractAssignment(state)?.createAssignment;

export const selectAssignmentData = (state: RootState) =>
  selectCreateContractAssignment(state)?.data;

export const selectReturnedContractId = (state: RootState) => selectCreateContractAssignment(state)?.returnedContractId;

export const selectAssignmentUpdatedData = (state: RootState) => selectCreateContractAssignment(state)?.updatedData;

export const selectAssignmentObjectInfo = (state: RootState) =>
  selectAssignmentData(state)?.objectInfo;

export const selectAssignmentArea = (state: RootState) =>
  selectAssignmentData(state)?.area;

export const selectAssignmentRooms = (state: RootState) =>
    selectAssignmentData(state)?.rooms;

export const selectAssignmentName = (state: RootState) =>
    selectAssignmentData(state)?.name;

export const selectAssignmentAddress = (state: RootState) =>
    selectAssignmentData(state)?.address;

export const selectAssignmentFlatNumber = (state: RootState) =>
  selectAssignmentData(state)?.flatNumber;

export const selectAssignmentLayout = (state: RootState) =>
  selectAssignmentData(state)?.layout;

export const selectAssignmentFloor = (state: RootState) =>
  selectAssignmentData(state)?.floor;

export const selectAssignmentMonthlyPayment = (state: RootState) =>
  selectAssignmentData(state)?.monthlyPayment;

export const selectAssignmentRestPayment = (state: RootState) =>
  selectAssignmentData(state)?.restPayment;

export const selectAssignmentRestMonths = (state: RootState) =>
  selectAssignmentData(state)?.restMonths;

export const selectAssignmentPaidSum = (state: RootState) =>
  selectAssignmentData(state)?.paidSum;

export const selectAssignmentPaidMonths = (state: RootState) =>
  selectAssignmentData(state)?.paidMonths;

export const selectAssignmentFullPaymentBoughtArea = (state: RootState) =>
  selectAssignmentData(state)?.fullPaymentBoughtAreaWorth;

export const selectAssignmentFullPaymentSellArea = (state: RootState) =>
  selectAssignmentData(state)?.fullPaymentSellAreaWorth;

export const selectAssignmentBoughtFirstPayment = (state: RootState) =>
  selectAssignmentData(state)?.boughtFirstPayment;

export const selectAssignmentMasterAgentMarkup = (state: RootState) =>
  selectAssignmentData(state)?.masterAgentMarkup;

export const selectAssignmentIsPrivate = (state: RootState) =>
    selectAssignmentData(state)?.private;

export const selectAssignmentRecalculationPercent = (state: RootState) =>
    selectAssignmentData(state)?.recalculationPercent;

export const selectAssignmentRecalculationPeriod = (state: RootState) =>
    selectAssignmentData(state)?.recalculationPeriod;

export const selectAssignmentImages = (state: RootState) => selectAssignmentData(state)?.images;


///// Contracts Board /////

export const selectContractAssignmentsList = (state: RootState) => selectContractAssignment(state)?.list;

export const selectContractAssignmentsListPending = (state: RootState) => selectContractAssignmentsList(state)?.pending;

export const selectContractAssignmentsListError = (state: RootState) => selectContractAssignmentsList(state)?.error;

export const selectContractAssignmentsListErrorMessage = (state: RootState) => selectContractAssignmentsList(state)?.errorMessage;

export const selectContractAssignmentsListLastPage = (state: RootState) => selectContractAssignmentsList(state)?.lastPage;

export const selectContractAssignmentsListCurrentPage = (state: RootState) => selectContractAssignmentsList(state)?.currentPage;

export const selectContractAssignmentsListData = (state: RootState) => selectContractAssignmentsList(state)?.data;

///////////////////////////

///// Contract Assignment Item /////

export const selectContractAssignmentItem = (state: RootState) => selectContractAssignment(state)?.contractAssignmentItem;

export const selectContractAssignmentItemPending = (state: RootState) => selectContractAssignmentItem(state)?.pending;

export const selectContractAssignmentItemError = (state: RootState) => selectContractAssignmentItem(state)?.error;

export const selectContractAssignmentItemErrorMessage = (state: RootState) => selectContractAssignmentItem(state)?.errorMessage;

export const selectContractAssignmentItemData = (state: RootState) => selectContractAssignmentItem(state)?.data;

///////////////////////////

///// Contract Assignment Editor /////

export const selectContractAssignmentEditor = (state: RootState) => selectContractAssignment(state)?.createAssignment;

export const selectContractAssignmentEditorPending = (state: RootState) => selectContractAssignmentEditor(state)?.pending;

export const selectContractAssignmentEditorError = (state: RootState) => selectContractAssignmentEditor(state)?.error;

export const selectContractAssignmentEditorErrorMessages = (state: RootState) => selectContractAssignmentEditor(state)?.errorMessages;

export const selectContractAssignmentEditorSuccess = (state: RootState) => selectContractAssignmentEditor(state)?.success;

///////////////////////////

///// Contract Assignment Editor Files Uploader /////

export const selectContractAssignmentEditorFilesUploader = (state: RootState) => selectContractAssignmentEditor(state)?.updatedData;

export const selectUpdatedImages = (state: RootState) => selectContractAssignmentEditorFilesUploader(state)?.images;

export const selectUpdatedLayout = (state: RootState) => selectContractAssignmentEditorFilesUploader(state)?.layout;

export const selectUpdatedImagesPending = (state: RootState) => selectUpdatedImages(state)?.pending;

export const selectUpdatedLayoutPending = (state: RootState) => selectUpdatedLayout(state)?.pending;

export const selectUpdatedImagesError = (state: RootState) => selectUpdatedImages(state)?.error;

export const selectUpdatedLayoutError = (state: RootState) => selectUpdatedLayout(state)?.error;

export const selectUpdatedImagesSuccess = (state: RootState) => selectUpdatedImages(state)?.success;

export const selectUpdatedLayoutSuccess = (state: RootState) => selectUpdatedLayout(state)?.success;

///////////////////////////

///// Contract Assignment Deleting /////

export const selectDeletingContractAssignment = (state: RootState) => selectContractAssignment(state)?.deletingContractId;

export const selectDeletingContractAssignmentPending = (state: RootState) => selectDeletingContractAssignment(state)?.pending;

export const selectDeletingContractAssignmentError = (state: RootState) => selectDeletingContractAssignment(state)?.error;

export const selectDeletingContractAssignmentSuccess = (state: RootState) => selectDeletingContractAssignment(state)?.success;

export const selectDeletingContractId = (state: RootState) => selectDeletingContractAssignment(state)?.value;

///////////////////////////


///// Contract Assignment User Info /////

export const selectContractUserBlock = (state: RootState) => selectContractAssignmentItem(state)?.userInfo;

export const selectContractUserPending = (state: RootState) => selectContractUserBlock(state)?.pending;

export const selectContractUserError = (state: RootState) => selectContractUserBlock(state)?.error;

export const selectContractUserData = (state: RootState) => selectContractUserBlock(state)?.data;

export const selectContractUserInfo = createSelector(
    [selectContractUserData],
    (userData) => ({
        name: userData?.user?.name,
        phone: userData?.user?.phone,
        avatarColor: userData?.user?.avatarColor,
        telegram: userData?.info?.telegram,
        whatsapp: userData?.info?.whatsapp,
        email: userData?.info?.email,
        instagram: userData?.info?.instagram,
    })
);



///////////////////////////
