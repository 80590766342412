import axios from "axios";
import { realtyStore } from "../store";
import { IMAGE_SIDES } from "../constants/common";
import { selectUserData } from "../store/selectors";

const bearerToken = document.cookie
  .split("; ")
  .find((row) => row.startsWith("userToken="))
  ?.split("=")[1];

let store;

export const injectStore = (storeVal) => {
  store = storeVal;
};

export const apiClient = axios.create({
  responseType: "json",
});

apiClient.interceptors.request.use((config) => {
  config.headers.authorization = `Bearer ${store.getState().userReducer.userData.token || decodeURIComponent(bearerToken)}`;
  return config;
});

export const getImageBiggerSide = (imageUrl: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      if (img.height > img.width) {
        resolve(IMAGE_SIDES.height);
      } else {
        resolve(IMAGE_SIDES.width);
      }
    };

    img.onerror = (error) => {
      console.error(`Ошибка загрузки изображения: ${imageUrl}`, error);
      reject(`Ошибка загрузки изображения: ${imageUrl}`);
    };

    img.src = imageUrl;
  });
};

export const isShared = window?.location?.pathname.includes("shared");

export const getRandomNumber = (max: number) =>
  Math.floor(Math.random() * (max + 1));

export const formatInternationalPhone = (phone) => {
  if (phone) {
    // Удаляем все символы, не являющиеся цифрами
    const digits = phone.replace(/\D/g, "");

    // Если номер состоит из 11 цифр и начинается с "8", заменяем первую цифру на "7"
    if (digits.length === 11 && digits.startsWith("8")) {
      return `+7${digits.slice(1)}`;
    }

    // Если номер состоит из 11 цифр и начинается с "7", просто добавляем +
    if (digits.length === 11 && digits.startsWith("7")) {
      return `+${digits}`;
    }

    // Если номер состоит из 10 цифр, предполагаем, что это российский номер без кода страны
    if (digits.length === 10) {
      return `+7${digits}`;
    }

    // Если номер длиннее 11 цифр или другая длина, возвращаем его с +
    return `+${digits}`;
  }
  return null;
};
