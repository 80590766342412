import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./styles.css";
import { RootState } from "../../../../store";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as Chevron } from "../../../../assets/icons/chevron.svg";
import { Input } from "../../../../components/common/Input";
import { TextArea } from "../../../../components/common/TextArea";
import {
  selectAssignmentData,
  selectAssignmentUpdatedData,
  selectContractAssignmentEditorError,
  selectContractAssignmentEditorErrorMessages,
  selectContractAssignmentEditorPending,
  selectContractAssignmentEditorSuccess,
  selectContractAssignmentItemData,
  selectReturnedContractId,
  selectUpdatedImagesSuccess,
  selectUpdatedLayoutSuccess,
} from "../../../../store/ContractAssignment/selectors";
import { ContractAssignmentActionCreator } from "../../../../store/ContractAssignment/actions";
import { Button } from "../../../../components/common/Button";
import { UploadFiles } from "../../../../components/common/UploadFiles";
import { InfoRow } from "../../../../components/common/InfoRow";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import {
  calculateBoughtFullPrice,
  calculateBuyersRequiredPayment,
  calculateClientsMarkup,
  calculatePaidPeriod,
  calculateSellFullPrice,
  handleSubmit as handleSubmitUtil,
} from "../../utils";
import { Switcher } from "../../../../components/common/Switcher";
import { InfoBar } from "../../../../components/common/InfoBar";
import { selectUserInfo } from "../../../../store/selectors";
import { Checkbox } from "../../../../components/common/Checkbox";
import { useResetEditor } from "./hooks/useResetEditor";
import { useOnSuccess } from "./hooks/useOnSuccess";
import { areaRegex, floorRegex, integerRegex, roomsRegex } from "./constants";
import { useForceReload } from "./hooks/useForceReload";

const _ContractAssignmentEditor = (props) => {
  const [isUploaderBusy, setIsUploaderBusy] = useState(false);
  const [isStudio, setIsStudio] = useState(false);
  const {
    user,
    pending,
    error,
    errorMessages,
    success,
    imagesSuccess,
    layoutSuccess,
    contractAssignmentsData,
    contractAssignmentUpdatedData,
    contractAssignmentItem,
    returnedContractId,
    setFields,
    createContractAssignment,
    updateContractAssignment,
    uploadNewFiles,
    deleteFiles,
    setEditingContract,
    getContractAssignment,
    clearEditingContract,
  } = props;

  const contractId = useParams().id;

  // Если данные ещё не загружены – используем пустой объект
  const data = contractAssignmentsData || {};

  // Значения по умолчанию (fallback)
  const {
    id,
    area = 0,
    name = "",
    rooms = 0,
    address = "",
    images,
    objectInfo = "",
    flatNumber = "",
    layout,
    floor = 0,
    monthlyPayment = 0,
    paidSum = 0,
    fullPaymentBoughtAreaWorth = 0,
    fullPaymentSellAreaWorth = 0,
    boughtFirstPayment = 0,
    masterAgentMarkup = 0,
    isPrivate = false,
  } = data;

  const isEditing = !!contractId;
  const updatedImages = contractAssignmentUpdatedData?.images || {};
  const updatedLayout = contractAssignmentUpdatedData?.layout || {};
  const deletingFilesIds = contractAssignmentUpdatedData?.deletingFilesIds;
  const shouldUploadImages =
    updatedImages.files && updatedImages.files.length > 0;
  const shouldUploadLayout =
    updatedLayout.files && updatedLayout.files.length > 0;
  const imagesUploaded = shouldUploadImages ? imagesSuccess : true;
  const layoutUploaded = shouldUploadLayout ? layoutSuccess : true;

  const clientsMarkup = calculateClientsMarkup(
    area,
    fullPaymentSellAreaWorth,
    fullPaymentBoughtAreaWorth,
  );
  const buyersRequiredPayment = calculateBuyersRequiredPayment(
    boughtFirstPayment,
    paidSum,
    masterAgentMarkup,
    clientsMarkup,
  );
  const objectsBoughtFullPrice = calculateBoughtFullPrice(
    area,
    fullPaymentBoughtAreaWorth,
  );
  const objectsSellFullPrice = calculateSellFullPrice(
    area,
    fullPaymentSellAreaWorth,
    masterAgentMarkup,
  );
  const paidPeriod = calculatePaidPeriod(paidSum, monthlyPayment);

  // Инициализация react-hook-form.
  // Если значение равно 0 (и не для rooms), используем пустую строку.
  const {
    register,
    handleSubmit,
    reset,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name,
      address,
      objectInfo,
      area: area === 0 ? "" : area,
      rooms, // оставляем значение для комнат (уже проверяется регуляркой)
      flatNumber,
      floor: floor === 0 ? "" : floor,
      monthlyPayment: monthlyPayment === 0 ? "" : monthlyPayment,
      paidSum: paidSum === 0 ? "" : paidSum,
      fullPaymentBoughtAreaWorth:
        fullPaymentBoughtAreaWorth === 0 ? "" : fullPaymentBoughtAreaWorth,
      fullPaymentSellAreaWorth:
        fullPaymentSellAreaWorth === 0 ? "" : fullPaymentSellAreaWorth,
      boughtFirstPayment: boughtFirstPayment === 0 ? "" : boughtFirstPayment,
      masterAgentMarkup: masterAgentMarkup === 0 ? "" : masterAgentMarkup,
      isPrivate,
    },
    mode: "onChange",
  });

  // Обновление формы при получении новых данных из Redux
  useResetEditor({ contractAssignmentsData, reset });

  // Обработчик отправки формы
  const onSubmit = (data) => {
    handleSubmitUtil({
      isEditing,
      isStudio,
      contractAssignmentsData: {
        ...contractAssignmentsData,
        ...data,
        paidMonths: paidPeriod,
      },
      shouldUploadImages,
      updatedImages,
      shouldUploadLayout,
      updatedLayout,
      deletingFilesIds,
      id,
      updateContractAssignment,
      uploadNewFiles,
      deleteFiles,
      createContractAssignment,
    });
  };

  useForceReload();

  useEffect(() => {
    clearEditingContract();
    if (contractId && user) {
      getContractAssignment({ id: contractId });
    }
  }, [contractId, user]);

  useEffect(() => {
    if (contractId && contractAssignmentItem && images) {
      setEditingContract(contractAssignmentItem);
    }
  }, [contractAssignmentItem, images]);

  useOnSuccess({
    success,
    id,
    imagesUploaded,
    layoutUploaded,
    returnedContractId,
    imagesSuccess,
    layoutSuccess,
  });

  const isMobile = useIsMobile();

  return (
    <>
      <Link className="payment_back-link" to={"/contract-assignment"}>
        <div className="contract-assignment-item_chevron">
          <Chevron width="16" height="16" fill="#000" />
        </div>
        назад
      </Link>
      <form
        className="contract-assignment-editor_container"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2>Переуступка</h2>
        <Input
          label="название объекта"
          name="name"
          register={register("name", {
            required: { message: "Название обязательно", value: true },
            onChange: (e) => setFields.name(e.target.value),
          })}
          error={!!errors?.name?.message}
          errorMessage={errors?.name?.message}
        />

        <Input
          label="адрес"
          name="address"
          register={register("address", {
            required: { message: "Адрес обязателен", value: true },
            onChange: (e) => setFields.address(e.target.value),
          })}
          error={!!errors?.address?.message}
          errorMessage={errors?.address?.message}
        />

        <TextArea
          label="информация об объекте"
          name="objectInfo"
          register={register("objectInfo", {
            required: { message: "Информация обязательна", value: true },
            onChange: (e) => setFields.objectInfo(e.target.value),
          })}
          error={!!errors?.objectInfo?.message}
          errorMessage={errors?.objectInfo?.message}
        />

        <UploadFiles
          isEditing={isEditing}
          setFilesArray={(value) => setFields.images(value)}
          fileTypes={["jpg", "png"]}
          label="фотографии объекта"
          maxFiles="10"
          maxSize="2097152"
          initialFiles={images}
          deletingFilesIds={contractAssignmentUpdatedData?.deletingFilesIds}
          setUpdatedFiles={(value) => setFields.updatedImages(value)}
          setDeletingFilesIds={(value) => setFields.deletingFilesIds(value)}
          setIsBusy={setIsUploaderBusy}
        />

        <Input
          label="площадь"
          name="area"
          register={register("area", {
            required: { message: "Площадь обязательна", value: true },
            onChange: (e) => setFields.area(e.target.value),
            pattern: {
              value: areaRegex,
              message:
                "Площадь должна быть положительным числом не более 10000",
            },
          })}
          error={!!errors?.area?.message}
          errorMessage={errors?.area?.message}
        />

        <Checkbox
          name="isStudio"
          label="студия"
          onChange={(value) => setIsStudio(value)}
        />

        {!isStudio && (
          <Input
            label="количество комнат"
            name="rooms"
            type="number"
            register={register("rooms", {
              required: {
                message: "Количество комнат обязательно",
                value: true,
              },
              onChange: (e) => setFields.rooms(e.target.value),
              pattern: {
                value: roomsRegex,
                message: "Количество комнат должно быть числом от 1 до 100",
              },
            })}
            error={!!errors?.rooms?.message}
            errorMessage={errors?.rooms?.message}
          />
        )}

        <Input
          label="номер квартиры"
          name="flatNumber"
          register={register("flatNumber", {
            required: { message: "Номер квартиры обязателен", value: true },
            onChange: (e) => setFields.flatNumber(e.target.value),
          })}
          error={!!errors?.flatNumber?.message}
          errorMessage={errors?.flatNumber?.message}
        />

        <UploadFiles
          isEditing={isEditing}
          setFilesArray={(value) => setFields.layout(value)}
          fileTypes={["pdf", "xls", "xlsx", "jpg", "png"]}
          label="файл планировки"
          maxFiles="1"
          maxSize="2097152"
          initialFiles={layout}
          deletingFilesIds={contractAssignmentUpdatedData?.deletingFilesIds}
          setUpdatedFiles={(value) => setFields.updatedLayout(value)}
          setDeletingFilesIds={(value) => setFields.deletingFilesIds(value)}
          setIsBusy={setIsUploaderBusy}
        />

        <Input
          label="этаж"
          name="floor"
          type="number"
          register={register("floor", {
            required: { message: "Этаж обязателен", value: true },
            onChange: (e) => setFields.floor(e.target.value),
            pattern: {
              value: floorRegex,
              message: "Этаж должен быть числом от -100 до 100",
            },
          })}
          error={!!errors?.floor?.message}
          errorMessage={errors?.floor?.message}
        />

        <Input
          label="ежемесячный платёж"
          name="monthlyPayment"
          type="number"
          register={register("monthlyPayment", {
            required: { message: "Обязательное поле", value: true },
            onChange: (e) => setFields.monthlyPayment(e.target.value),
            pattern: {
              value: integerRegex,
              message: "Ежемесячный платёж должен быть целым числом",
            },
          })}
          error={!!errors?.monthlyPayment?.message}
          errorMessage={errors?.monthlyPayment?.message}
        />

        <Input
          label="оплачено (руб)"
          name="paidSum"
          type="number"
          register={register("paidSum", {
            required: { message: "Обязательное поле", value: true },
            onChange: (e) => setFields.paidSum(e.target.value),
            pattern: {
              value: integerRegex,
              message: "Оплачено должно быть целым числом",
            },
          })}
          error={!!errors?.paidSum?.message}
          errorMessage={errors?.paidSum?.message}
        />

        <Input
          label="стоимость квадрата при покупке (полная оплата)"
          name="fullPaymentBoughtAreaWorth"
          type="number"
          register={register("fullPaymentBoughtAreaWorth", {
            required: { message: "Обязательное поле", value: true },
            onChange: (e) =>
              setFields.fullPaymentBoughtAreaWorth(e.target.value),
            pattern: {
              value: integerRegex,
              message:
                "Стоимость квадрата при покупке должна быть целым числом",
            },
          })}
          error={!!errors?.fullPaymentBoughtAreaWorth?.message}
          errorMessage={errors?.fullPaymentBoughtAreaWorth?.message}
        />

        <Input
          label="стоимость квадрата при продаже (полная оплата)"
          name="fullPaymentSellAreaWorth"
          type="number"
          register={register("fullPaymentSellAreaWorth", {
            required: { message: "Обязательное поле", value: true },
            onChange: (e) => setFields.fullPaymentSellAreaWorth(e.target.value),
            pattern: {
              value: integerRegex,
              message:
                "Стоимость квадрата при продаже должна быть целым числом",
            },
          })}
          error={!!errors?.fullPaymentSellAreaWorth?.message}
          errorMessage={errors?.fullPaymentSellAreaWorth?.message}
        />

        <Input
          label="первый взнос при покупке"
          name="boughtFirstPayment"
          type="number"
          register={register("boughtFirstPayment", {
            required: { message: "Обязательное поле", value: true },
            onChange: (e) => setFields.boughtFirstPayment(e.target.value),
            pattern: {
              value: integerRegex,
              message: "Первый взнос должен быть целым числом",
            },
          })}
          error={!!errors?.boughtFirstPayment?.message}
          errorMessage={errors?.boughtFirstPayment?.message}
        />

        <Input
          label="комиссия агента"
          name="masterAgentMarkup"
          type="number"
          register={register("masterAgentMarkup", {
            required: { message: "Обязательное поле", value: true },
            onChange: (e) => setFields.masterAgentMarkup(e.target.value),
            pattern: {
              value: integerRegex,
              message: "Комиссия агента должна быть целым числом",
            },
          })}
          error={!!errors?.masterAgentMarkup?.message}
          errorMessage={errors?.masterAgentMarkup?.message}
        />

        <Switcher
          className="contract-assignment-editor_switcher"
          label="показывать другим риэлторам"
          onChange={() => setFields.isPrivate(!isPrivate)}
          checked={!isPrivate}
        />

        <InfoRow
          title="стоимость объекта при покупке (полная оплата)"
          value={objectsBoughtFullPrice}
          unit="₽"
        />
        <InfoRow
          title="стоимость объекта при продаже (полная оплата)"
          value={objectsSellFullPrice}
          unit="₽"
        />
        <InfoRow title="Наценка клиента" value={clientsMarkup} unit="₽" />
        <InfoRow
          title="Взнос покупателя"
          value={buyersRequiredPayment}
          unit="₽"
        />
        <InfoRow title="Оплачено (месяцев)" value={paidPeriod} unit="мес" />

        {error && (
          <InfoBar
            text={
              errorMessages?.common ||
              "Не удалось сохранить объявление. Попробуйте снова позже."
            }
          />
        )}
        <Button
          className="contract-assignment-item_button"
          text="Сохранить"
          pending={pending}
          type="submit"
          fullWidth={isMobile}
          disabled={isUploaderBusy}
        />
      </form>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUserInfo(state),
  pending: selectContractAssignmentEditorPending(state),
  error: selectContractAssignmentEditorError(state),
  errorMessages: selectContractAssignmentEditorErrorMessages(state),
  success: selectContractAssignmentEditorSuccess(state),
  imagesSuccess: selectUpdatedImagesSuccess(state),
  layoutSuccess: selectUpdatedLayoutSuccess(state),
  contractAssignmentItem: selectContractAssignmentItemData(state),
  contractAssignmentsData: selectAssignmentData(state),
  contractAssignmentUpdatedData: selectAssignmentUpdatedData(state),
  returnedContractId: selectReturnedContractId(state),
});

const mapDispatchToProps = (dispatch) => ({
  setFields: {
    area: (value) => dispatch(ContractAssignmentActionCreator.setArea(value)),
    name: (value) => dispatch(ContractAssignmentActionCreator.setName(value)),
    rooms: (value) => dispatch(ContractAssignmentActionCreator.setRooms(value)),
    address: (value) =>
      dispatch(ContractAssignmentActionCreator.setAddress(value)),
    images: (files) =>
      dispatch(ContractAssignmentActionCreator.setImages(files)),
    updatedImages: (files) =>
      dispatch(ContractAssignmentActionCreator.setUpdatedImages(files)),
    objectInfo: (value) =>
      dispatch(ContractAssignmentActionCreator.setObjectInfo(value)),
    flatNumber: (value) =>
      dispatch(ContractAssignmentActionCreator.setFlatNumber(value)),
    layout: (files) =>
      dispatch(ContractAssignmentActionCreator.setLayout(files)),
    updatedLayout: (files) =>
      dispatch(ContractAssignmentActionCreator.setUpdatedLayout(files)),
    floor: (value) => dispatch(ContractAssignmentActionCreator.setFloor(value)),
    monthlyPayment: (value) =>
      dispatch(ContractAssignmentActionCreator.setMonthlyPayment(value)),
    paidSum: (value) =>
      dispatch(ContractAssignmentActionCreator.setPaidSum(value)),
    fullPaymentBoughtAreaWorth: (value) =>
      dispatch(
        ContractAssignmentActionCreator.setFullPaymentBoughtAreaWorth(value),
      ),
    fullPaymentSellAreaWorth: (value) =>
      dispatch(
        ContractAssignmentActionCreator.setFullPaymentSellAreaWorth(value),
      ),
    boughtFirstPayment: (value) =>
      dispatch(ContractAssignmentActionCreator.setBoughtFirstPayment(value)),
    masterAgentMarkup: (value) =>
      dispatch(ContractAssignmentActionCreator.setMasterAgentMarkup(value)),
    isPrivate: (value) =>
      dispatch(ContractAssignmentActionCreator.setPrivate(value)),
    deletingFilesIds: (value) =>
      dispatch(ContractAssignmentActionCreator.setDeletingFilesIds(value)),
  },
  createContractAssignment: (data) =>
    dispatch(ContractAssignmentActionCreator.createContractAssignment(data)),
  updateContractAssignment: (data) =>
    dispatch(ContractAssignmentActionCreator.updateContractAssignment(data)),
  uploadNewFiles: (data) =>
    dispatch(ContractAssignmentActionCreator.uploadNewFiles(data)),
  deleteFiles: (data) =>
    dispatch(ContractAssignmentActionCreator.deleteFiles(data)),
  getContractAssignment: (data) =>
    dispatch(ContractAssignmentActionCreator.getContractAssignmentItem(data)),
  setEditingContract: (data) =>
    dispatch(ContractAssignmentActionCreator.setEditingContract(data)),
  clearEditingContract: () =>
    dispatch(ContractAssignmentActionCreator.clearEditingContract()),
});

export const ContractAssignmentEditor = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_ContractAssignmentEditor);
