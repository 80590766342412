export const RENDER_MODES = [
    {
        id: 1,
        name: "все объявления",
    },
    {
        id: 2,
        name: "мои объявления",
    },
];
