import React from 'react';
import { components } from 'react-select';

const CustomOption = (props) => {
    return (
        <components.Option {...props}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>{props.data.label}</span>
                {/* Если у опции задана иконка, отображаем её справа */}
                {props.data.icon && <span>{props.data.icon}</span>}
                {props?.data?.value?.rightSideText && <span>{props?.data?.value?.rightSideText}</span>}
            </div>
        </components.Option>
    );
};

export default CustomOption;
