const translateField = (errorMessage) => {
  const fieldTranslations = {
    name: "название объекта",
    address: "адрес",
    objectInfo: "информация об объекте",
    area: "площадь",
    rooms: "количество комнат",
    flatNumber: "номер квартиры",
    floor: "этаж",
    monthlyPayment: "ежемесячный платёж",
    paidSum: "оплачено",
    fullPaymentBoughtAreaWorth: "стоимость квадрата при покупке (полная оплата)",
    fullPaymentSellAreaWorth: "стоимость квадрата при продаже (полная оплата)",
    boughtFirstPayment: "первый взнос при покупке",
    masterAgentMarkup: "комиссия агента",
    isPrivate: "видимость",
  };

  let translatedMessage = errorMessage;

  // Для каждого поля ищем его в строке ошибки и заменяем на перевод.
  Object.keys(fieldTranslations).forEach((field) => {
    // Используем регулярное выражение, чтобы заменить слово целиком (без затрагивания частей других слов)
    const regex = new RegExp(`\\b${field}\\b`, "gi");
    translatedMessage = translatedMessage?.replace(regex, `"${fieldTranslations[field]}"`);
  });

  return translatedMessage;
}

export const handleResponse = (response) => {
  if (response.payload.status !== 200) {
    return {
      pending: false,
      error: true,
      errorMessage: response.payload.data.message,
      success: false,
    };
  }
  return {
    pending: false,
    error: false,
    errorMessage: null,
    data: response.payload.data,
  };
};

export const handleCreatingResponse = (response) => {
  if (response.payload.status !== 200) {
    const errorsArray = response?.payload?.data?.errors || {
      common: translateField(response?.payload?.data?.data),
    };
    return {
      pending: false,
      error: true,
      errorMessages: errorsArray,
      success: false,
    };
  }
  return {
    pending: false,
    error: false,
    errorMessages: [],
    success: true,
    returnedContractId: response.payload.data.data.id,
  };
};

export const removeElementById = (array, id) =>
  array.filter((item) => item.id !== id);
