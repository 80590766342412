export const calculateSellFullPrice = (area, sellAreaWorth, markup) =>
    area * sellAreaWorth + Number(markup);

export const calculateBoughtFullPrice = (area, sellAreaWorth) =>
    area * sellAreaWorth;

export const calculateClientsMarkup = (area, sellAreaWorth, boughtAreaWorth) =>
    Math.round(area * sellAreaWorth - area * boughtAreaWorth);

export const calculateRequiredPayment = (
    boughtFirstPayment,
    paidSum,
    markup,
    clientsMarkup
) =>
    Math.round(
        Number(boughtFirstPayment) + Number(paidSum) + Number(markup) + clientsMarkup
    );

export const calculateBuyersRequiredPayment = (
    boughtFirstPayment,
    paidSum,
    masterAgentMarkup,
    clientsMarkup
) => {
    return Math.round(
        Number(boughtFirstPayment) +
        Number(paidSum) +
        Number(masterAgentMarkup) +
        Number(clientsMarkup)
    );
};

export const calculatePaidPeriod = (paidSum, monthlyPayment) => {
    if (monthlyPayment === 0) return 0; // Avoid division by zero
    return Math.floor(paidSum / monthlyPayment);
};

export const calculateInstallmentFullPrice = () => null;

export const getFilesPaths = (files, full = false) => files?.map(item => {
    if (full) {
        return item.path;
    }
    return item.path_600 || item.path;
});

export const sortByDate = (data, order = "newest") => {
    const dataCopy = [...data];

    return dataCopy.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);

        return order === "newest" ? dateB - dateA : dateA - dateB;
    });
};

// utils/contractAssignmentUtils.js

export const handleSubmit = ({
                                 isEditing,
    isStudio,
                                 contractAssignmentsData,
                                 shouldUploadImages,
                                 updatedImages,
                                 shouldUploadLayout,
                                 updatedLayout,
                                 deletingFilesIds,
                                 id,
                                 updateContractAssignment,
                                 uploadNewFiles,
                                 deleteFiles,
                                 createContractAssignment,
                             }) => {
    const finalRooms = isStudio ? 0 : contractAssignmentsData.rooms;
    if (isEditing) {
        // Синхронное обновление данных
        updateContractAssignment({
            ...contractAssignmentsData,
            rooms: finalRooms,
        });

        // Синхронная загрузка изображений
        if (shouldUploadImages) {
            uploadNewFiles({
                id,
                type: "images",
                files: updatedImages.files,
            });
        }

        // Синхронная загрузка макета
        if (shouldUploadLayout) {
            uploadNewFiles({
                id,
                type: "layout",
                files: updatedLayout.files,
            });
        }

        // Синхронное удаление файлов
        if (deletingFilesIds && deletingFilesIds.length > 0) {
            deleteFiles({
                assignmentId: id,
                ids: deletingFilesIds,
            });
        }
    } else {
        // Синхронное создание данных
        createContractAssignment({
            ...contractAssignmentsData,
            rooms: finalRooms,
        });
    }
};
