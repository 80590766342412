import React from "react";
import "./styles.css";

export const TextArea = ({
  value,
  onChange,
  label,
  placeholder,
  error,
  errorMessage,
  name,
  register,
}) => {
  const handleOnChange = (e) => {
    const value = e.target.value;
    onChange(value);
  };
  return (
    <>
      {label && <div className="textarea_label">{label}</div>}
      <div className="textarea_container">
        <textarea
          className={"textarea_main" + (error ? " textarea_error" : "")}
          name={name}
          onChange={handleOnChange}
          value={value}
          placeholder={placeholder}
          {...register}
        />
      </div>
      {errorMessage && (
        <div className="textarea_error-message">{errorMessage}</div>
      )}
    </>
  );
};
