import React, { useEffect, useState } from "react";
import "./styles.css";
import { Slider } from "../../../../pages/HousingComplex/components/Slider";
import { RootState } from "../../../../store";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { ContractAssignmentActionCreator } from "../../../../store/ContractAssignment/actions";
import {
  selectContractAssignmentItemData,
  selectContractAssignmentItemError,
  selectContractAssignmentItemErrorMessage,
  selectContractAssignmentItemPending,
  selectContractUserInfo,
} from "../../../../store/ContractAssignment/selectors";
import { InfoRow } from "../../../../components/common/InfoRow";
import { PlanItem } from "../../../../pages/HousingComplex/components/Plans/components/PlanItem";
import { getClippedText } from "../../../../pages/HousingComplex/utils";
import {
  calculateClientsMarkup,
  calculateRequiredPayment,
  calculateSellFullPrice,
  getFilesPaths,
} from "../../utils";
import { ReactComponent as Chevron } from "../../../../assets/icons/chevron.svg";
import { ContractAssignmentItemSkeleton } from "./ContractAssignmentItemSkeleton";
import { Button } from "../../../../components/common/Button";
import { Error } from "../../../../components/common/Error";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { Recalculation } from "../Recalculation";
import { selectUserInfo } from "../../../../store/selectors";
import { UserActionCreator } from "../../../../store/userActions";
import { UserCard } from "../../../../components/common/UserCard";

export const _ContractAssignmentItem = ({
  contractAssignmentItem,
  pending,
  error,
  errorMessage,
  user,
  contractUserInfo,
  getContractAssignment,
  getUserInfoById,
}) => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [shouldRenderRecalculation, setShouldRenderRecalculation] =
    useState(false);
  const CLIPPED_TEXT_LENGTH = 10;
  const contractAssignmentId = useParams()?.id;
  const isMobile = useIsMobile();

  useEffect(() => {
    if (user) {
      getContractAssignment({ id: contractAssignmentId });
    }
  }, [user]);

  useEffect(() => {
    if (contractAssignmentItem && contractAssignmentItem.userId) {
      getUserInfoById({ id: contractAssignmentItem.userId });
    }
  }, [contractAssignmentItem]);

  if (error) {
    return (
      <Error
        title="Что-то пошло не так"
        description="Не удалось загрузить объявление. Попробуйте ещё раз позже."
        buttonText="повторить"
        retryCallback={() =>
          getContractAssignment({ id: contractAssignmentId })
        }
      />
    );
  }

  if (!contractAssignmentId || pending) {
    return <ContractAssignmentItemSkeleton />;
  }

  const {
    objectInfo,
    area,
    address,
    images,
    layout,
    name,
    floor,
    rooms,
    fullPaymentSellAreaWorth,
    masterAgentMarkup,
    boughtFirstPayment,
    paidSum,
    paidMonths,
    fullPaymentBoughtAreaWorth,
  } = contractAssignmentItem;
  const formattedRooms = Number(rooms) === 0 ? 'студия' : rooms;
  const { clippedText, textLength } = getClippedText(
    objectInfo,
    CLIPPED_TEXT_LENGTH,
  );
  const canBeClipped = textLength && textLength > CLIPPED_TEXT_LENGTH;
  const description = isDescriptionExpanded ? objectInfo : clippedText;
  const fullPrice = calculateSellFullPrice(
    area,
    fullPaymentSellAreaWorth,
    masterAgentMarkup,
  );
  const clientsMarkup = calculateClientsMarkup(
    area,
    fullPaymentSellAreaWorth,
    fullPaymentBoughtAreaWorth,
  );
  const requiredPayment = calculateRequiredPayment(
    boughtFirstPayment,
    paidSum,
    masterAgentMarkup,
    clientsMarkup,
  );

  const imagesPaths = getFilesPaths(images, true);
  const layoutPath = getFilesPaths(layout, true);

  return (
    <>
      <Link className="payment_back-link" to={"/contract-assignment/"}>
        <div className="contract-assignment-item_chevron">
          <Chevron width="16" height="16" fill="#000" />
        </div>
        назад
      </Link>
      <Slider images={imagesPaths} />
      <div className="contract-assignment-item_container">
        <div className="contract-assignment-item_title">{name}</div>
        <div className="contract-assignment-item_info">
          <div>{address || "нет адреса"}</div>
        </div>
        <div
          className="contract-assignment-item_description"
          onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
        >
          {description || "нет описания"}
          {!isDescriptionExpanded && canBeClipped && <b> подробнее...</b>}
        </div>
        {layout.length > 0 && (
          <PlanItem
            className="contract-assignment-item_layout"
            item={{
              name: "планировка",
              src: layoutPath[0],
            }}
          />
        )}
        <InfoRow value={area} title="площадь" unit="м²" />
        <InfoRow value={formattedRooms} title="количество комнат" />
        <InfoRow value={floor} title="этаж" />
        <InfoRow
          value={fullPaymentSellAreaWorth}
          title="стоимость квадрата"
          unit="₽"
        />
        <InfoRow value={fullPrice} title="полная стоимость объекта" unit="₽" />
        <InfoRow value={requiredPayment} title="взнос" unit="₽" />
        <InfoRow value={paidMonths} title="оплачено месяцев" />
        {contractUserInfo && contractUserInfo?.name?.length > 0 && (
            <UserCard user={contractUserInfo} />
        )}
        {/*<Button*/}
        {/*  text="перерасчёт"*/}
        {/*  onClick={() => setShouldRenderRecalculation(true)}*/}
        {/*  fullWidth={isMobile}*/}
        {/*/>*/}
        {shouldRenderRecalculation && <Recalculation />}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  contractAssignmentItem: selectContractAssignmentItemData(state),
  pending: selectContractAssignmentItemPending(state),
  error: selectContractAssignmentItemError(state),
  errorMessage: selectContractAssignmentItemErrorMessage(state),
  user: selectUserInfo(state),
  contractUserInfo: selectContractUserInfo(state),
});

const mapDispatchToProps = {
  getContractAssignment:
    ContractAssignmentActionCreator.getContractAssignmentItem,
  getUserInfoById: UserActionCreator.getUserInfoById,
};

export const ContractAssignmentItem = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_ContractAssignmentItem);
