import React from "react";
import "./styles.css";
import { ReactComponent as Loader } from "../../../assets/loading_icon.svg";
import { getButtonContent } from "./utils";

export const Button = ({
  className,
  icon,
  iconOnly,
  text,
  iconLeft,
  iconRight,
  onClick,
  pending,
  fullWidth,
  disabled,
}) => {
  const content = getButtonContent(icon, text, iconOnly);
  const isDisabled = disabled || pending;
  return (
    <button
      disabled={isDisabled}
      onClick={onClick}
      className={
        `button_main ${className} ` +
        (fullWidth ? " button_full-width" : "")
      }
    >
      {pending ? (
        <Loader height="18px" width="18px" fill="#fff" stroke="#fff" />
      ) : (
        content
      )}
    </button>
  );
};
