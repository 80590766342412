import React from "react";
import "./styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Navigation, Pagination, Scrollbar, Autoplay } from "swiper/modules";
import "swiper/css/bundle";

export const About = () => {
  return (
    <div className="about_container">
      <div className="about_description">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          autoplay={true}
          spaceBetween={50}
          slidesPerView={1}
          pagination={{
            clickable: true,
            el: ".slider_pagination",
          }}
          scrollbar={{ draggable: true }}
          className="about_slider"
        >
          <SwiperSlide>
            <div
              className="about_slide"
              style={{
                backgroundImage: `url(https://husamhelper.ru/storage/images/phpbl9lgllkuusf75ewce2.jpeg)`,
              }}
            >
                <div className="about_side-title">
                    база недвижимости
                </div>
                <div className="about_side-text">
                    актуальная информация о новых
                    объектах. с подробностями и фотографиями.
                </div>
            </div>
          </SwiperSlide>
            <SwiperSlide>
                <div
                    className="about_slide"
                    style={{
                        backgroundImage: `url(https://husamhelper.ru/storage/images/phpkrpc3ali0po16E9MxEP.png)`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "bottom center",
                    }}
                >
                    <div className="about_side-title">
                        калькуляторы рассрочки
                    </div>
                    <div className="about_side-text">
                        быстрый расчет условий рассрочки по первому
                        взносу и сроку для ваших клиентов.
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div
                    className="about_slide"
                    style={{
                        backgroundImage: `url(https://husamhelper.ru/storage/images/phpqvjd4lpaerk86tF28aH.png)`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "bottom center",
                    }}
                >
                    <div className="about_side-title">
                        доска переуступок
                    </div>
                    <div className="about_side-text">
                        добавляйте свои объявления, управляйте ими
                        и скрывайте от других пользователей при необходимости.
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};
