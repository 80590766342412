import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { store, RootState, realtyStore } from "../../../store";
import { UserActionCreator } from "../../../store/userActions";
import { connect } from "react-redux";
import { selectUserPending, selectUserToken } from "../../../store/selectors";
import { injectStore } from "../../../utils";

export const _AuthHoc = ({ pending, children, getUserInfo, token }) => {
  const PRIVATE_ROUTES = {
    test: "/lol",
    payment: "/payment",
    verifyEmail: "/profile/verify",
    housingComplex: "/housing-complex/",
    contractAssignment: "/contract-assignment"
  };
  const AUTH_ROUTES = {
    signIn: "/signin",
    signUp: "/signup",
    signInWithSlash: "/signin/",
    signUpWithSlash: "/signup/",
    resetPassword: "/reset-password",
  };
  const [cookies, setCookie] = useCookies();
  const params = useLocation();
  const navigate = useNavigate();
  const userToken = cookies.userToken;
  const rememberMe = cookies.rememberMe;
  const url = params.pathname.toLowerCase();
  const isPrivate =
    Object.values(PRIVATE_ROUTES).some((route) => url.startsWith(route)) ||
    url === "/" ||
    url === "";
  const isAuthRoute = Object.values(AUTH_ROUTES).includes(
    params.pathname.toLowerCase(),
  );
  const isAuth = (!!userToken && userToken !== null) || !!token;
  const [shouldRender, setShouldRender] = useState(false);
  const maxAge = rememberMe ? 60 * 60 * 24 * 365 : undefined;
  useEffect(() => {
    injectStore(realtyStore);
  });

  useEffect(() => {
    if (userToken) {
      getUserInfo();
    }
  }, []);

  useEffect(() => {
    if (!userToken || userToken !== token) {
      setCookie("userToken", token, { path: "/", maxAge });
    }
  }, [token]);

  useEffect(() => {
    setShouldRender(true);
    if (!isAuth && isPrivate && !pending) {
      navigate(AUTH_ROUTES.signIn);
    }
    if (isAuth && isAuthRoute && !pending) {
      navigate("/");
    }
  });

  if (shouldRender) {
    return children;
  }
};

const mapStateToProps = (state: RootState) => ({
  token: selectUserToken(state),
  pending: selectUserPending(state),
});

const mapDispatchToProps = {
  getUserInfo: UserActionCreator.getUserInfo,
};

export const AuthHoc = connect(mapStateToProps, mapDispatchToProps)(_AuthHoc);
