import React from "react";
import { ReactComponent as XLSIcon } from "../../../../../assets/icons/fileTypes/xls.svg";
import { ReactComponent as PDFIcon } from "../../../../../assets/icons/fileTypes/pdf.svg";
import { ReactComponent as UnknownIcon } from "../../../../../assets/icons/fileTypes/unknown.svg";
import { ReactComponent as ImageIcon } from "../../../../../assets/icons/fileTypes/img.svg";
import { getFormattedFileTypeName } from "../../utils";

export const FileTypeIcon = ({ fileExtension, fileType, width = 56, height = 56 }) => {
  const formattedFileType = fileExtension || getFormattedFileTypeName(fileType);
  switch (formattedFileType) {
    case "pdf": {
      return <PDFIcon width={width} height={height} />;
    }
    case "xls": {
      return <XLSIcon width={width} height={height} />;
    }
    case "xlsx": {
      return <XLSIcon width={width} height={height} />;
    }
    case "png": {
      return <ImageIcon width={width} height={height} />;
    }
    case "jpg": {
      return <ImageIcon width={width} height={height} />;
    }
    case "gif": {
      return <ImageIcon width={width} height={height} />;
    }
    default: {
      return <UnknownIcon width={width} height={height} />;
    }
  }
};
