import { INSTALLMENT_PLANS_LIB } from "../../../../../constants/common.ts";

export const getFirstPayment = (
  installmentOption,
  requiredPayment,
  area,
  flatViewType,
) => {
  if (installmentOption.value.id === INSTALLMENT_PLANS_LIB.without.name) {
    return area * requiredPayment;
  }
  const areaWorth = installmentOption.value?.prices[flatViewType?.value?.id];
  const fullPrice = area * areaWorth;
  const percent =
    installmentOption.value.id === INSTALLMENT_PLANS_LIB.full.name
      ? 0
      : installmentOption.value.id.replace("%", "");
  return Math.ceil((percent / 100) * fullPrice);
};

export const getInstallmentPlans = (floorRange, installmentPeriod) => {
  let installmentPlans = {};
  floorRange?.value?.prices.map((priceItem) => {
    const installmentPeriodCondition = installmentPeriod
      ? (priceItem.installmentPeriod === installmentPeriod || installmentPeriod === '0')
      : true;
    const fullPrice = priceItem.installmentPlan === 'full';
    if (
      priceItem.installmentPlan &&
      !installmentPlans.hasOwnProperty(priceItem.installmentPlan) &&
      installmentPeriodCondition && !fullPrice
    ) {
      installmentPlans[priceItem.installmentPlan] = {
        name:
          INSTALLMENT_PLANS_LIB[priceItem.installmentPlan]?.label ||
          priceItem.installmentPlan,
        value: {
          id: priceItem.installmentPlan,
          prices: {
            commonFlat: priceItem.commonFlat,
            fancyViewFlat: priceItem.fancyViewFlat,
          },
        },
      };
    }
  });
  return installmentPlans;
};

export const getBasePrice = (floorRange) => {
  let basePrice = {};
  floorRange?.value?.prices?.map(item => {
    if (item.installmentPlan === 'full') {
      basePrice = {
        commonFlat: item.commonFlat,
        fancyViewFlat: item.fancyViewFlat,
      }
    }
  })
  return basePrice;
}

export const getLowestOption = (obj) => {
  if (obj["without"]) {
    return obj["without"];
  }

  // Фильтруем ключи, которые представляют проценты
  const percentageKeys = Object.keys(obj).filter(key => key.endsWith('%'));

  // Находим ключ с наименьшим процентом
  const smallestPercentageKey = percentageKeys.sort(
      (a, b) => parseFloat(a) - parseFloat(b)
  )[0];

  return obj[smallestPercentageKey];
}
