import React from "react";
import "./styles.css";
import { ReactComponent as DefaultImage } from "../../../../../assets/building.svg";
import { Skeleton } from "../../../../../components/common/Skeleton";

export const ContractAssignmentItemSkeleton = () => {
  return (
    <div className="contract-assignment-item-skeleton_container">
      <div className="contract-assignment-item-skeleton_image">
        <DefaultImage width="100%" height="100%" />
      </div>
      <Skeleton className="contract-assignment-item-skeleton_title" />
      <Skeleton className="contract-assignment-item-skeleton_address" />
        <Skeleton className="contract-assignment-item-skeleton_row" />
    </div>
  );
};
