import React from "react";
import "./styles.css";
import { Input } from "../../../../components/common/Input";
import { RootState } from "../../../../store";
import {
  selectAssignmentRecalculationPercent,
  selectAssignmentRecalculationPeriod,
  selectContractAssignmentItemData,
} from "../../../../store/ContractAssignment/selectors";
import { ContractAssignmentActionCreator } from "../../../../store/ContractAssignment/actions";
import { connect } from "react-redux";
import { InfoRow } from "../../../../components/common/InfoRow";

const _Recalculation = ({
  contractAssignmentItem,
  recalculationPercent,
  recalculationPeriod,
  setRecalculationPercent,
  setRecalculationPeriod,
}) => {
  const newWorth =
    (Number(contractAssignmentItem.fullPaymentBoughtAreaWorth) / 100) *
      recalculationPercent +
    Number(contractAssignmentItem.fullPaymentBoughtAreaWorth);

  const full = Math.round(newWorth * contractAssignmentItem.area);
  const sell = full - Number(contractAssignmentItem.paidSum);

  return (
    <>
      <h2>Перерасчет</h2>
      <Input
        value={recalculationPercent}
        onChange={setRecalculationPercent}
        label="процент рассрочки"
        type="number"
      />
      <Input
        value={recalculationPeriod}
        onChange={setRecalculationPeriod}
        label="срок рассрочки"
        type="number"
      />
      {!!recalculationPercent && (
        <InfoRow value={sell} title="стоимость в рассрочку" unit="₽" />
      )}
      {!!recalculationPercent && !!recalculationPeriod && (
        <InfoRow
          value={Math.ceil(sell / recalculationPeriod)}
          title="ежемесячный платеж"
          unit="₽"
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  contractAssignmentItem: selectContractAssignmentItemData(state),
  recalculationPercent: selectAssignmentRecalculationPercent(state),
  recalculationPeriod: selectAssignmentRecalculationPeriod(state),
});

const mapDispatchToProps = {
  setRecalculationPercent:
    ContractAssignmentActionCreator.setRecalculationPercent,
  setRecalculationPeriod:
    ContractAssignmentActionCreator.setRecalculationPeriod,
};

export const Recalculation = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_Recalculation);
