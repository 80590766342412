import React from "react";
import "./styles.css";
import { Skeleton } from "../../../Skeleton";

export const InstallmentPlanSkeleton = () => {
  return (
    <>
      <Skeleton className="installment-skeleton_title" />
      <Skeleton className="installment-skeleton_block" />
      <Skeleton className="installment-skeleton_block" />
      <Skeleton className="installment-skeleton_block" />
    </>
  );
};
