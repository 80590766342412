import { createReducer } from "@reduxjs/toolkit";
import { ContractAssignmentActionCreator } from "./actions";
import {
  handleCreatingResponse,
  handleResponse,
  removeElementById,
} from "./utils";
import { UserActionCreator } from "../userActions";

const initialState = {
  list: {
    data: [],
    currentPage: 1,
    lastPage: 0,
    pending: false,
    error: false,
  },
  contractAssignmentItem: {
    pending: true,
    error: false,
    errorMessage: null,
    response: null,
    data: null,
    userInfo: {
      pending: false,
      error: false,
      data: {},
    },
  },
  createAssignment: {
    pending: false,
    error: false,
    errorMessages: {},
    response: null,
    success: false,
    data: {
      objectInfo: "",
      images: [],
      area: 0,
      rooms: 0,
      address: "",
      name: "",
      flatNumber: "",
      layout: [],
      floor: "",
      monthlyPayment: 0,
      restPayment: 0,
      restMonths: 0,
      paidSum: 0,
      paidMonths: 0,
      fullPaymentBoughtAreaWorth: 0,
      fullPaymentSellAreaWorth: 0,
      boughtFirstPayment: 0,
      masterAgentMarkup: 0,
      recalculationPercent: 0,
      recalculationPeriod: 0,
      isPrivate: false,
    },
    updatedData: {
      images: {
        pending: false,
        error: false,
        success: false,
        files: [],
      },
      layout: {
        pending: false,
        error: false,
        success: false,
        files: [],
      },
      deletingFilesIds: [],
    },
  },
  deletingContractId: {
    pending: false,
    success: false,
    error: false,
    value: undefined,
  },
};

export const contractAssignmentReducer = createReducer(
  initialState,
  (builder) => {
    builder
      .addCase(
        ContractAssignmentActionCreator.setObjectInfo,
        (state, action) => {
          state.createAssignment.data.objectInfo = action.payload;
        },
      )
      .addCase(ContractAssignmentActionCreator.setArea, (state, action) => {
        state.createAssignment.data.area = action.payload;
      })
      .addCase(ContractAssignmentActionCreator.setName, (state, action) => {
        state.createAssignment.data.name = action.payload;
      })
      .addCase(ContractAssignmentActionCreator.setRooms, (state, action) => {
        state.createAssignment.data.rooms = action.payload;
      })
      .addCase(ContractAssignmentActionCreator.setAddress, (state, action) => {
        state.createAssignment.data.address = action.payload;
      })
      .addCase(
        ContractAssignmentActionCreator.setFlatNumber,
        (state, action) => {
          state.createAssignment.data.flatNumber = action.payload;
        },
      )
      .addCase(ContractAssignmentActionCreator.setLayout, (state, action) => {
        state.createAssignment.data.layout = action.payload;
      })
      .addCase(
        ContractAssignmentActionCreator.setUpdatedLayout,
        (state, action) => {
          state.createAssignment.updatedData.layout.files = action.payload;
        },
      )
      .addCase(ContractAssignmentActionCreator.setFloor, (state, action) => {
        state.createAssignment.data.floor = action.payload;
      })
      .addCase(
        ContractAssignmentActionCreator.setMonthlyPayment,
        (state, action) => {
          state.createAssignment.data.monthlyPayment = action.payload;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.setRestPayment,
        (state, action) => {
          state.createAssignment.data.restPayment = action.payload;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.setRestMonths,
        (state, action) => {
          state.createAssignment.data.restMonths = action.payload;
        },
      )
      .addCase(ContractAssignmentActionCreator.setPaidSum, (state, action) => {
        state.createAssignment.data.paidSum = action.payload;
      })
      .addCase(
        ContractAssignmentActionCreator.setPaidMonths,
        (state, action) => {
          state.createAssignment.data.paidMonths = action.payload;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.setFullPaymentBoughtAreaWorth,
        (state, action) => {
          state.createAssignment.data.fullPaymentBoughtAreaWorth =
            action.payload;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.setFullPaymentSellAreaWorth,
        (state, action) => {
          state.createAssignment.data.fullPaymentSellAreaWorth = action.payload;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.setBoughtFirstPayment,
        (state, action) => {
          state.createAssignment.data.boughtFirstPayment = action.payload;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.setMasterAgentMarkup,
        (state, action) => {
          state.createAssignment.data.masterAgentMarkup = action.payload;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.setRecalculationPercent,
        (state, action) => {
          state.createAssignment.data.recalculationPercent = action.payload;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.setRecalculationPeriod,
        (state, action) => {
          state.createAssignment.data.recalculationPeriod = action.payload;
        },
      )
      .addCase(ContractAssignmentActionCreator.setImages, (state, action) => {
        state.createAssignment.data.images = action.payload;
      })
      .addCase(
        ContractAssignmentActionCreator.setUpdatedImages,
        (state, action) => {
          state.createAssignment.updatedData.images.files = action.payload;
        },
      )
      .addCase(ContractAssignmentActionCreator.setPrivate, (state, action) => {
        state.createAssignment.data.isPrivate = action.payload;
      })
      .addCase(
        ContractAssignmentActionCreator.setDeletingFilesIds,
        (state, action) => {
          state.createAssignment.updatedData.deletingFilesIds = action.payload;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.setDeletingContractId,
        (state, action) => {
          state.deletingContractId.value = action.payload;
          state.deletingContractId.success = false;
          state.deletingContractId.error = false;
          state.deletingContractId.pending = false;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.setEditingContract,
        (state, action) => {
          state.createAssignment.data = action.payload;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.clearEditingContract,
        (state) => {
          state.createAssignment = initialState.createAssignment;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.getContractAssignments.pending,
        (state) => {
          state.list.pending = true;
          state.list.error = null;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.getContractAssignments.fulfilled,
        (state, action) => {
          state.list.pending = false;
          state.list.data = state.list?.data
            ? [...state.list.data, ...action.payload.data.data]
            : action.payload.data.data;
          state.list.currentPage = action.payload.data.current_page;
          state.list.lastPage = action.payload.data.last_page;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.getContractAssignments.rejected,
        (state, action) => {
          state.list.pending = false;
          state.list.error = action.payload.data || "Something went wrong";
        },
      )
      .addCase(
        ContractAssignmentActionCreator.getContractAssignmentsByUser.pending,
        (state) => {
          state.list.pending = true;
          state.list.error = null;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.getContractAssignmentsByUser.fulfilled,
        (state, action) => {
          state.list.pending = false;
          state.list.data = state.list?.data
            ? [...state.list.data, ...action.payload.data.data]
            : action.payload.data.data;
          state.list.currentPage = action.payload.data.current_page;
          state.list.lastPage = action.payload.data.last_page;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.getContractAssignmentsByUser.rejected,
        (state, action) => {
          state.list.pending = false;
          state.list.error = action.payload.data || "Something went wrong";
        },
      )
      .addCase(
        ContractAssignmentActionCreator.clearContractAssignments,
        (state) => {
          state.list.data = [];
        },
      )
      .addCase(
        ContractAssignmentActionCreator.getContractAssignmentItem.pending,
        (state) => {
          state.contractAssignmentItem.pending = true;
          state.contractAssignmentItem.error = false;
          state.contractAssignmentItem.errorMessage = null;
          state.contractAssignmentItem.data = null;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.getContractAssignmentItem.fulfilled,
        (state, action) => {
          const result = handleResponse(action);
          state.contractAssignmentItem = result;
          state.contractAssignmentItem.userInfo = initialState.contractAssignmentItem.userInfo;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.getContractAssignmentItem.rejected,
        (state) => {
          state.contractAssignmentItem.pending = false;
          state.contractAssignmentItem.error = true;
          state.contractAssignmentItem.data = null;
        },
      )
      .addCase(UserActionCreator.getUserInfoById.pending, (state) => {
        state.contractAssignmentItem.userInfo.pending = true;
        state.contractAssignmentItem.userInfo.error = false;
        state.contractAssignmentItem.userInfo.data = {};
      })
      .addCase(UserActionCreator.getUserInfoById.fulfilled, (state, action) => {
        state.contractAssignmentItem.userInfo.pending = false;
        state.contractAssignmentItem.userInfo.error = false;
        state.contractAssignmentItem.userInfo.data = action.payload.data;
      })
      .addCase(UserActionCreator.getUserInfoById.rejected, (state) => {
        state.contractAssignmentItem.userInfo.pending = false;
        state.contractAssignmentItem.userInfo.error = true;
        state.contractAssignmentItem.userInfo.data = {};
      })
      .addCase(
        ContractAssignmentActionCreator.deleteContractAssignmentItem.pending,
        (state) => {
          state.deletingContractId.pending = true;
          state.deletingContractId.error = false;
          state.deletingContractId.success = false;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.deleteContractAssignmentItem.fulfilled,
        (state) => {
          state.deletingContractId.pending = false;
          state.deletingContractId.error = false;
          state.deletingContractId.success = true;
          state.list.data = removeElementById(
            state.list.data,
            state.deletingContractId.value,
          );
        },
      )
      .addCase(
        ContractAssignmentActionCreator.deleteContractAssignmentItem.rejected,
        (state) => {
          state.deletingContractId.pending = false;
          state.deletingContractId.error = true;
          state.deletingContractId.success = false;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.createContractAssignment.pending,
        (state) => {
          state.createAssignment.pending = true;
          state.createAssignment.error = false;
          state.createAssignment.errorMessages = [];
          state.createAssignment.success = false;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.createContractAssignment.fulfilled,
        (state, action) => {
          const { pending, error, errorMessages, success, returnedContractId } =
            handleCreatingResponse(action);
          state.createAssignment.pending = pending;
          state.createAssignment.success = success;
          state.createAssignment.error = error;
          state.createAssignment.errorMessages = errorMessages;
          state.createAssignment.returnedContractId = returnedContractId;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.createContractAssignment.rejected,
        (state) => {
          state.createAssignment.pending = false;
          state.createAssignment.error = true;
          state.createAssignment.success = false;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.updateContractAssignment.pending,
        (state) => {
          state.createAssignment.pending = true;
          state.createAssignment.error = false;
          state.createAssignment.success = false;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.updateContractAssignment.fulfilled,
        (state) => {
          state.createAssignment.pending = false;
          state.createAssignment.success = true;
          state.createAssignment.error = false;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.updateContractAssignment.rejected,
        (state) => {
          state.createAssignment.pending = false;
          state.createAssignment.error = true;
          state.createAssignment.success = false;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.uploadNewFiles.pending,
        (state, action) => {
          const fileType = action.meta.arg.type;
          state.createAssignment.updatedData[fileType].pending = true;
          state.createAssignment.updatedData[fileType].error = false;
          state.createAssignment.updatedData[fileType].success = false;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.uploadNewFiles.fulfilled,
        (state, action) => {
          const fileType = action.meta.arg.type;
          state.createAssignment.updatedData[fileType].pending = false;
          state.createAssignment.updatedData[fileType].success = true;
          state.createAssignment.updatedData[fileType].error = false;
        },
      )
      .addCase(
        ContractAssignmentActionCreator.uploadNewFiles.rejected,
        (state, action) => {
          const fileType = action.meta.arg.type;
          state.createAssignment.updatedData[fileType].pending = false;
          state.createAssignment.updatedData[fileType].error = true;
          state.createAssignment.updatedData[fileType].success = false;
        },
      );
  },
);
