import {INSTALLMENT_PLAN_TYPES} from "../constants";

export const getLastUpdateDate = (plan, pricesByFirstPayment, byPeriod) => {
    if (pricesByFirstPayment && plan === INSTALLMENT_PLAN_TYPES.byFirstPayment.id) {
        return pricesByFirstPayment.updatedAt;
    }
    if (byPeriod && plan === INSTALLMENT_PLAN_TYPES.byPeriod.id) {
        return byPeriod.updatedAt;
    }
    return null;
}
