import React from "react";
import './styles.css';
import { ReactComponent as DefaultImage } from "../../../../../../assets/building.svg";
import {Skeleton} from "../../../../../../components/common/Skeleton";

export const ContractAssignmentListSkeleton = ({}) => {
    return (
        <ul className="contract-assignment-list-skeleton_container">
            {Array.from({ length: 6 }, () => (
                <li className="contract-assignment-list-skeleton_item">
                    <div className="contract-assignment-list-skeleton_img">
                        <DefaultImage width="70%" height="70%"/>
                    </div>
                    <div className="contract-assignment-list-skeleton_info">
                        <Skeleton className="contract-assignment-list-skeleton_title"/>
                        <Skeleton className="contract-assignment-list-skeleton_description"/>
                    </div>
                </li>
            ))}
        </ul>
    )
}
