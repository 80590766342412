import React, { useEffect } from "react";
import "./styles.css";
import { Input } from "../../components/common/Input";
import { Button } from "../../components/common/Button";
import { useForm } from "react-hook-form";
import { REGEXES } from "../../constants/common";
import { RootState } from "../../store";
import { UserActionCreator } from "../../store/userActions";
import { connect } from "react-redux";
import {
  selectIsAuth,
  selectUserError,
  selectUserErrorMessage,
  selectUserPending,
  selectUserToken,
} from "../../store/selectors";
import { useCookies } from "react-cookie";
import {Link} from "react-router-dom";

export const _SignUp = ({
  isAuth,
  token,
  signUpRequest,
  pending,
  error,
  errorMessage,
                          setNotification,
}) => {
  const [cookies, setCookie] = useCookies(["userToken"]);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const emailError = errors.email?.message;
  const phoneError = errors.phone?.message;
  const nameError = errors.name?.message;
  const passwordError = errors.password?.message;
  const checkPasswordError = errors.checkPassword?.message;
  const onSubmit = (data) => {
    if (data.password !== data.checkPassword) {
      setError("checkPassword", { message: "пароли не совпадают" });
    }
    if (data.password === data.checkPassword) {
      signUpRequest(data);
    }
  };

  useEffect(() => {
    if (token) {
      setCookie("userToken", token, { path: "/" });
      setNotification({
        isOpen: true,
        value: {
          title: 'Отлично!',
          description: `Вы успешно зарегистрировались. На ваш e-mail отправлено письмо с ссылкой для подтверждения вашей электронной почты. Обязательно подтвердите вашу электронную почту, это нужно для восстановления доступа к аккаунту.`,
          closeIcon: true,
        }
      })
    }
  }, [token]);
  useEffect(() => {
    setError("email", { message: errorMessage });
  }, [errorMessage, error]);

  return (
    <>
      <div className="sign-up_container">
        <h2>Регистрация</h2>
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="sign-up_form-container"
        >
          <div className="sign-up_input-item">
            <Input
                register={register("email", {
                  required: {message: "введите e-mail", value: true},
                  pattern: {
                    message: "некорректный e-mail",
                    value: REGEXES.email,
                  },
                })}
                name="areaWorth"
                type="email"
                placeholder="e-mail"
                label="введите e-mail"
                error={!!emailError}
                errorMessage={emailError}
            />
          </div>
          <div className="sign-up_input-item">
            <Input
                register={register("name", {
                  required: {message: "введите имя", value: true},
                })}
                name="areaWorth"
                type="text"
                placeholder="имя"
                label="введите ваше имя"
                error={!!nameError}
                errorMessage={nameError}
            />
          </div>
          <div className="sign-up_input-item">
            <Input
                register={register("phone", {
                  required: {message: "введите номер телефона", value: true},
                  pattern: {
                    message: "некорректный номер телефона",
                    value: REGEXES.phone,
                  },
                })}
                name="phone"
                placeholder="номер телефона"
                label="введите действующий номер телефона"
                error={!!phoneError}
                errorMessage={phoneError}
            />
          </div>
          <div className="sign-up_input-item">
            <Input
                register={register("whatsapp")}
                name="whatsapp"
                placeholder="WhatsApp"
                label="WhatsApp (необязательно)"
            />
          </div>
          <div className="sign-up_input-item">
            <Input
                register={register("telegram")}
                name="telegram"
                placeholder="Telegram"
                label="Telegram (необязательно)"
            />
          </div>
          <div className="sign-up_input-item">
            <Input
                register={register("instagram")}
                name="instagram"
                placeholder="Instagram"
                label="Instagram (необязательно)"
            />
          </div>
          <div className="sign-up_input-item">
            <Input
                register={register("password", {
                  required: {message: "введите пароль", value: true},
                  pattern: {
                    message: "некорректный пароль",
                    value: REGEXES.password,
                  },
                })}
                name="areaWorth"
                type="password"
                placeholder="пароль"
                label="введите пароль"
                error={!!passwordError}
                errorMessage={passwordError}
            />
          </div>
          <div className="sign-up_input-item">
            <Input
                register={register("checkPassword", {
                  required: {message: "введите пароль", value: true},
                  pattern: {
                    message: "некорректный пароль",
                    value: REGEXES.password,
                  },
                })}
                name="areaWorth"
                type="password"
                placeholder="пароль"
                label="повторите пароль"
                error={!!checkPasswordError}
                errorMessage={checkPasswordError}
            />
          </div>
          <div className="sign-up_input-item">
            <Button pending={pending} text="регистрация"/>
          </div>
        </form>
        <div className="sign-up_reg-advice">
          Уже есть аккаунт?{" "}
          <Link className="sign-in_link" to="/signin/">
            Авторизация
          </Link>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  pending: selectUserPending(state),
  error: selectUserError(state),
  errorMessage: selectUserErrorMessage(state),
  isAuth: selectIsAuth(state),
  token: selectUserToken(state),
});

const mapDispatchToProps = {
  signUpRequest: UserActionCreator.userSignUp,
  setNotification: UserActionCreator.setNotification,
};

export const SignUp = connect(mapStateToProps, mapDispatchToProps)(_SignUp);
