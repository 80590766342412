import React from "react";
import "./styles.css";

export const Checkbox = ({
                             label,
                             name,
                             register, // передаётся, например, из react-hook-form
                             value,
                             onChange,
                             checked,
                             error,
                             errorMessage,
                             ...props
                         }) => {
    return (
        <div className="checkbox_container">
            <label className="checkbox_label">
                {/* Скрытый нативный чекбокс */}
                <input
                    type="checkbox"
                    name={name}
                    checked={checked}
                    onChange={(e) => onChange && onChange(e.target.checked)}
                    {...register}
                    className="checkbox_native"
                    {...props}
                />
                {/* Кастомное оформление */}
                <span className="checkbox_custom"></span>
                <span className="checkbox_text">{label}</span>
            </label>
            {error && <div className="checkbox_error-message">{errorMessage}</div>}
        </div>
    );
};
