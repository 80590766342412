import React, {useEffect} from "react";

export const useOnSuccess = ({
    success,
    id,
                                 imagesUploaded,
                                 layoutUploaded,
                                 returnedContractId,
                                 imagesSuccess,
                                 layoutSuccess
                             }) => {
    useEffect(() => {
        if (success && id && imagesUploaded && layoutUploaded) {
            window.location.href = `/contract-assignment/${id}`;
        }
        if (success && !id && returnedContractId) {
            window.location.href = `/contract-assignment/${returnedContractId}`;
        }
    }, [success, imagesSuccess, layoutSuccess]);
}
