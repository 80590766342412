import React from "react";
import "./styles.css";
import { Tabs } from "../../Tabs";
import { ByFirstPayment } from "./ByFirstPayment";
import { ByPeriod } from "./ByPeriod";
import { INSTALLMENT_PLAN_TYPES } from "../constants.ts";
import { ShareUSP } from "../../../../pages/HousingComplex/components/ShareUSP";
import { PaidHoc } from "../../../hocs/PaidHoc";
import { Error } from "../../../../components/common/Error";

export const InstallmentPlan = ({
  option,
  setOption,
  hasMultiTypes,
  housing,
  installmentPlans,
  shareData,
  setShareData,
}) => {
  // const noPrices =
  //   pricesByFirstPayment?.floorRanges?.length === 0 &&
  //   pricesByPeriod?.floorRanges?.length === 0;
  //
  // if (noPrices) {
  //   return (
  //     <Error
  //       title="Данные о ценах отсутствуют"
  //       description="Возможно, скоро они появятся."
  //     />
  //   );
  // }

  return (
    <>
      <div className="installment_title">
        Рассрочка {INSTALLMENT_PLAN_TYPES[option]?.name}
      </div>
      <PaidHoc>
        {hasMultiTypes && (
          <Tabs
            activeTab={option}
            setActiveTab={setOption}
            tabs={installmentPlans}
          />
        )}
        {option === INSTALLMENT_PLAN_TYPES.byFirstPayment.id && (
          <ByFirstPayment
            housingName={housing.housingName}
            setShareData={setShareData}
          />
        )}
        {option === INSTALLMENT_PLAN_TYPES.byPeriod.id && (
          <ByPeriod
            housingName={housing.housingName}
            setShareData={setShareData}
          />
        )}
        <ShareUSP shareData={shareData} housing={housing} />
      </PaidHoc>
    </>
  );
};
